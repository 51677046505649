export const UPDATE_COMPANY_DETAILS_REQUEST_START = 'UPDATE_COMPANY_DETAILS_REQUEST_START';
export const UPDATE_COMPANY_DETAILS_REQUEST_FAIL = 'UPDATE_COMPANY_DETAILS_REQUEST_FAIL';
export const UPDATE_COMPANY_DETAILS_REQUEST_SUCCESS = 'UPDATE_COMPANY_DETAILS_REQUEST_SUCCESS';

export const CREATE_COMPANY_DETAILS_REQUEST_START = 'CREATE_COMPANY_DETAILS_REQUEST_START';
export const CREATE_COMPANY_DETAILS_REQUEST_FAIL = 'CREATE_COMPANY_DETAILS_REQUEST_FAIL';
export const CREATE_COMPANY_DETAILS_REQUEST_SUCCESS = 'CREATE_COMPANY_DETAILS_REQUEST_SUCCESS';

export const GET_COMPANY_DETAILS_REQUEST_START = 'GET_COMPANY_DETAILS_REQUEST_START';
export const GET_COMPANY_DETAILS_REQUEST_FAIL = 'GET_COMPANY_DETAILS_REQUEST_FAIL';
export const GET_COMPANY_DETAILS_REQUEST_SUCCESS = 'GET_COMPANY_DETAILS_REQUEST_SUCCESS';

export const INVOICE_COMPANY_LIST_REQUEST_START = 'INVOICE_COMPANY_LIST_REQUEST_START';
export const INVOICE_COMPANY_LIST_REQUEST_FAIL = 'INVOICE_COMPANY_LIST_REQUEST_FAIL';
export const INVOICE_COMPANY_LIST_REQUEST_SUCCESS = 'INVOICE_COMPANY_LIST_REQUEST_SUCCESS';

export const INCORPORATION_COMPANY_LIST_REQUEST_START = 'INCORPORATION_COMPANY_LIST_REQUEST_START';
export const INCORPORATION_COMPANY_LIST_REQUEST_FAIL = 'INCORPORATION_COMPANY_LIST_REQUEST_FAIL';
export const INCORPORATION_COMPANY_LIST_REQUEST_SUCCESS = 'INCORPORATION_COMPANY_LIST_REQUEST_SUCCESS';

export const BUSINESS_COMPANY_LIST_REQUEST_START = 'BUSINESS_COMPANY_LIST_REQUEST_START';
export const BUSINESS_COMPANY_LIST_REQUEST_FAIL = 'BUSINESS_COMPANY_LIST_REQUEST_FAIL';
export const BUSINESS_COMPANY_LIST_REQUEST_SUCCESS = 'BUSINESS_COMPANY_LIST_REQUEST_SUCCESS';

export const APPLICATION_ACCESS_REQUEST_START = 'APPLICATION_ACCESS_REQUEST_START';
export const APPLICATION_ACCESS_REQUEST_FAIL = 'APPLICATION_ACCESS_REQUEST_FAIL';
export const APPLICATION_ACCESS_REQUEST_SUCCESS = 'APPLICATION_ACCESS_REQUEST_SUCCESS';
