import React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import InputAdornment from '@mui/material/InputAdornment';
export default function TextInput({
  margin,
  textFieldContainerClass,
  className,
  name,
  label,
  placeholder,
  inputRef,
  error,
  helperText,
  onChange,
  value,
  type,
  onKeyDown,
  max,
  defaultValue,
  disabled,
  onKeyPress,
  InputLabelProps,
  startAdornment,
  backgroundColor,
  endAdornment,
  onRightSideAdornment,
  onBlur,
  onFocus,
  readOnly
}) {
  return (
    <div className={`${margin} ${textFieldContainerClass}`}>
      <TextField
        className={` ${className}`}
        readOnly={readOnly}
        InputProps={{
          disableUnderline: true,
          startAdornment: startAdornment && <InputAdornment position={'start'}>{startAdornment}</InputAdornment>,
          endAdornment: endAdornment && (
            <endAdornment position={'end'}>
              <div className="cursor-pointer password-eye rounded-full" onClick={onRightSideAdornment}>
                {endAdornment}{' '}
              </div>
            </endAdornment>
          )
        }}
        inputProps={{ maxLength: max }}
        name={name}
        label={label}
        placeholder={placeholder}
        sx={{
          '& .MuiOutlinedInput-root': {
            backgroundColor: backgroundColor,
            borderRadius: '8px'
          },
          '& .MuiInputBase-input': {
            paddingLeft: startAdornment && '0px'
          },
          '& .MuiFormHelperText-root': {
            minWidth: 'auto'
          }
        }}
        value={value}
        onChange={onChange}
        inputRef={inputRef}
        error={error}
        helperText={helperText}
        type={type}
        onKeyDown={onKeyDown}
        defaultValue={defaultValue}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        InputLabelProps={InputLabelProps}
      />
    </div>
  );
}

TextInput.propTypes = {
  margin: PropTypes.string,
  textFieldContainerClass: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  inputRef: PropTypes.any,
  error: PropTypes.any,
  helperText: PropTypes.any,
  onChange: PropTypes.any,
  value: PropTypes.any,
  type: PropTypes.string,
  onKeyDown: PropTypes.any,
  placeholder: PropTypes.string,
  max: PropTypes.number,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  onKeyPress: PropTypes.any,
  backgroundColor: PropTypes.string,
  InputLabelProps: PropTypes.object,
  startAdornment: PropTypes.any,
  endAdornment: PropTypes.any,
  onRightSideAdornment: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func
};

TextInput.defaultProps = {
  className: 'w-full',
  type: 'string',
  disabled: false,
  backgroundColor: '#FFFFFF'
};
