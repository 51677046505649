import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import { isBrowser } from '../../helper/helpers';
import { PAGE_TITLE, LOGIN_TAB, IS_SSO_ON, MARKETING_LOGIN_URL } from '../../constants/enum';

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('user') //Get LocalStorage Data of User
    ? JSON.parse(window.localStorage.getItem('user'))
    : {};

const setUser = async (user, activeTab) => {
  typeof window !== 'undefined' && window.localStorage.setItem('user', JSON.stringify(user)); //Set LocalStorage Data of User
  user?.companyId && window.sessionStorage.setItem('companyId', user?.companyId);
  if (activeTab === LOGIN_TAB?.INVOICE) {
    navigate(PATH_PAGE.dashboard);
  } else if (isLoggedIn()) {
    navigate(PATH_PAGE.dashboard);
  } else {
    navigate(IS_SSO_ON ? MARKETING_LOGIN_URL : PATH_PAGE.login);
  }
  isBrowser() && window.localStorage.setItem('pageName', JSON.stringify(PAGE_TITLE.DASHBOARD));
};
const navigateToLogin = () => navigate(IS_SSO_ON ? MARKETING_LOGIN_URL : PATH_PAGE.login);

export const handleLogin = (token, userDetails, activeTab) => {
  if (token) {
    sessionStorage.setItem('token', token);
    setUser(
      {
        id: userDetails?._id,
        isVerified: userDetails?.isVerified,
        email: userDetails?.email,
        firstName: userDetails?.firstName,
        lastName: userDetails?.lastName,
        isCompanyOwner: userDetails?.isCompanyOwner,
        isIncorporation: activeTab === LOGIN_TAB?.INVOICE ? false : userDetails?.isIncorporation,
        isApproved: userDetails?.isApproved,
        companyId: userDetails?.companyId
      },
      activeTab
    );
    return true;
  }
  return false;
};

export const isLoggedIn = () => {
  const token = sessionStorage.getItem('token');
  return token ? true : false;
};

export const clearStorage = () => {
  sessionStorage.clear();
  isBrowser() && window.localStorage.clear();
  setUser({});
};

export const logOut = () => {
  clearStorage();
  navigateToLogin();
};
