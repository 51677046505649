import React from 'react';
import Layout from '../../components/layout';
import CompanyDetailsModal from '../../components/common/CompanyDetailsModal';
function Start() {
  return (
    <div>
      <Layout hideSideBar={true}>
        <div className="flex justify-center items-center" style={{ height: '75vh' }}>
          <CompanyDetailsModal isModal={false} />
        </div>
      </Layout>
    </div>
  );
}

export default Start;
