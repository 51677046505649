import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BarChart from '../components/dashboard/BarChart';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import Layout from '../components/layout';
import Page from '../components/Page';
import { API_CODE, PAGE_TITLE, RESPONSIVENESS_BREAKPOINT_VALUE, TYPE_OF_INVOICE } from '../constants/enum';
import { PATH_PAGE } from '../routes/paths';
import PropTypes from 'prop-types';
import InvoiceCurrenciesList from '../data/invoice-currencies-list.json';
import * as invoiceActions from '../components/invoice/reduxStore/action';
import * as profileActions from '../components/profile/reduxStore/action';
import AccountReceivable from '../components/paymentReview/AccountReceivable';
import PrimaryButton from '../components/ui/buttons/PrimaryButton';
import ImportedInvoiceUploadModal from '../components/importedInvoices/ImportedInvoiceUploadModal';
import '../styles/pages/dashboard.scss';
import DashboardBanner from '../components/dashboard/dashboardBanner';
import StatrysLoader from '../components/ui/loaders/StatrysLoader';
import InvoiceHighlightsModal from '../components/dashboard/InvoiceHighlightsModal';
import { useCookies } from 'react-cookie';
import CompanyDetailsModal from '../components/common/CompanyDetailsModal';
import useWindowDimensions from '../hooks/useWindowDimensions';
import Seo from '../components/seo';

function Dashboard({ invoiceCurrency, getCurrencyPreference }) {
  const [totalAmountCurrency, setTotalAmountCurrency] = useState('');
  const [currency, setCurrency] = useState();
  const [uploadModal, setUploadModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPerformaceData, setIsPerformaceData] = useState(false);
  const [open, setOpen] = useState();
  const [cookies] = useCookies(['latestHighlights']);
  const closeDynamicModal = () => {
    setUploadModal(false);
  };
  const { width } = useWindowDimensions();

  const getPreferedCurrency = async () => {
    const getCurrencyDataResponse = await getCurrencyPreference();
    if (getCurrencyDataResponse?.status === API_CODE.STATUS_200) {
      setCurrency(getCurrencyDataResponse?.data?.data?.currency);
    }
  };

  const totalInvoiceAmount = async () => {
    setIsLoading(true);
    const payload = {
      dashboardType: TYPE_OF_INVOICE.INVOICE
    };
    const invoiceTotalAmountResponse = await invoiceCurrency({ params: { ...payload } });
    if (invoiceTotalAmountResponse?.status === API_CODE.STATUS_200) {
      setTotalAmountCurrency(invoiceTotalAmountResponse?.data?.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (cookies?.latestHighlights?.getLatestData && Object.keys(cookies).length !== 0) {
      setOpen(false);
    } else {
      setOpen(true);
    }
    getPreferedCurrency();
    totalInvoiceAmount();
  }, []);
  return (
    <div>
      <Layout>
        <Page title={PAGE_TITLE.DASHBOARD}>
          <CompanyDetailsModal isModal={true} />
          <HeaderBreadcrumbs
            subtitleClass={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'mb-2' : 'mb-0'}
            titleContainerClass={{
              flexDirection: ['column', 'row', 'row'],
              alignItems: ['left', 'center', 'center'],
              width: '100%'
            }}
            heading={PAGE_TITLE.DASHBOARD}
            sx={{ height: ['100%', '56px', '56px'], display: 'flex', alignItems: 'center' }}
            links={[{ name: '' }]}
            action={
              <div className="flex md:flex-row gap-1">
                <PrimaryButton
                  id="dashboard-upload-invoice-btn"
                  onClick={() => setUploadModal(true)}
                  className="mr-2 dashboard-invoice-upload-button"
                  bgColor="bg-white"
                  fontWeight="text-bold"
                  color="text-coral-500"
                  caption="Upload Invoice"
                  type="small"
                />
                <PrimaryButton
                  id="dashboard-create-invoice-btn"
                  urlLink={PATH_PAGE.createInvoice}
                  fontWeight="text-bold"
                  className="dashboard-invoice-upload-button"
                  caption="Create Invoice"
                  type="small"
                />
              </div>
            }
          />
        </Page>
        <ImportedInvoiceUploadModal openDynamicModal={uploadModal} closeDynamicModal={closeDynamicModal} />
        {isLoading ? (
          <StatrysLoader />
        ) : (
          <>
            <div className="flex md:flex-row flex-col md:gap-0 gap-6 mb-10">
              <div className="md:order-1 order-2 w-full">
                <BarChart preferedCurrency={currency} isPerformanceData={() => setIsPerformaceData(true)} />
              </div>
              <div className="md:order-2 order-1 md:mt-0 mt-6">
                {Number(totalAmountCurrency?.Paid) > 0 && (
                  <AccountReceivable totalAmountCurrency={totalAmountCurrency} currency={currency} />
                )}
              </div>
            </div>
            {Number(totalAmountCurrency?.Paid) === 0 && !isPerformaceData && <DashboardBanner />}
          </>
        )}
      </Layout>
    </div>
  );
}

export const Head = () => (
  <Seo
    title="Dashboard - Invoicing | Statrys"
    description="Create, upload, edit and send your invoices and get paid faster with Statrys."
  />
);

const mapDispatchToProps = (dispatch) => {
  return {
    invoiceCurrency: (invoiceData) => dispatch(invoiceActions.invoiceCurrency(invoiceData)),
    getCurrencyPreference: (payload) => dispatch(profileActions.getCurrencyPreference(payload))
  };
};

export default connect(null, mapDispatchToProps)(Dashboard);

Dashboard.propTypes = {
  invoiceCurrency: PropTypes.func,
  getCurrencyPreference: PropTypes.func
};
