export const API_CODE = {
  STATUS_200: 200,
  STATUS_201: 201,
  STATUS_204: 204
};
export const API_ERROR_CODES = {
  STATUS_400: 400,
  STATUS_401: 401,
  STATUS_402: 402,
  STATUS_403: 403,
  STATUS_404: 404,
  STATUS_412: 412,
  STATUS_422: 422,
  STATUS_405: 405
};

export const REGEX = {
  UPPER_CASE_REGEX: /(?=.*[A-Z])/,
  LOWER_CASE_REGEX: /(?=.*[a-z])/,
  DIGIT_CASE_REGEX: /(?=.*\d)/,
  SPECIAL_CASE_REGEX: /(?=.*[-+_!@#$%^&*.,?])/,
  SPACE_REGEX: /(?=.*\s)/,
  EMAIL_REGEX: /^(?=[\w\s-+.])\s*[-+.'\w]+@[-.\w]+\.[-.\w]+\s*$/,
  HUBSPOT_COOKIE_REGEX: '(^|;)\\s*hubspotutk\\s*=\\s*([^;]+)',
  ONLY_STRING_REGEX: /([A-Za-z])$/,
  REPLACE_STRING_REGEX: /[^\d.-]/g,
  TAGS_NOT_ALLOWED: /^((?!<>|<|>).)*$/s,
  NEW_LINE: /(?:\r\n|\r|\n)/g,
  NUMBER_CHECKER_REGEX: /^[0-9]+$/,
  GET_NUMBER_WITH_DECIMAL: /[^0-9\.-]+/g,
  GET_VALUE_WITHOUT_DECIMAL_INT: /[0-9\.]/g,
  IS_GREATER_THAN_ZERO: /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/,
  PASSPORT_NUMBER: /[a-zA-Z]{2}[0-9]{7}/
};

export const FILE_UPLOAD_TYPE = {
  PDF: 'application/pdf',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  MSWORD: 'application/msword',
  OFFICE_DOCUMENT: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  URL: 'url'
};

export const FILE_EXTENTION_TYPE = {
  PDF: 'pdf',
  JPEG: 'jpeg',
  DOCX: 'docx',
  PNG: 'png',
  DOC: 'doc',
  JPG: 'jpg'
};

export const MUI_COLORS = {
  CORAL: '#E15741',
  WHITE: '#FFFFFF',
  SILVER_MEDAL: '#D6D6D6',
  SUNSET_ORANGE: '#FF4F42',
  EXTRA_LIGHT_GRAY: '#F9F9F9',
  PURPLE: '#2D0D45',
  DARK_CHARCOAL: '#2F2F2F',
  DARK_GRAY: '#474747',
  GRAY: '#7A7A7A',
  LIGHT_CREAM: '#F9DBD1',
  LIGHT_GREEN: '#E6F2F1',
  LIGHT_YELLOW: '#FFEDDE',
  LIGHT_GRAY: '#F5F1ED',
  DARK_GRAY_MAIN: '#464646',
  GREEN: '#097D75',
  SUNGLOW: '#FFC730',
  BLUE: '#1B6BD3',
  WHATS_APP_GREEN: '#25D365',
  PINK: '#FCEFE8',
  PAPAYA_WHIP: '#FFF3D4',
  DARK_GREEN: '#043734',
  MID_NIGHT: '#212B36',
  DARK_GRAYISH_BLUE: '#919EAB',
  CORAL_PINK: '#F6D0CA',
  GRAY_FOR_DISABLE_INPUTS: '#FAFAFA'
};
export const AVATAR_COlORS = ['#2D0D45', '#097D75', '#043734', '#1B6BD3', '#FFC730', '#F9DBD1', '#DAD2BC', '#E2FBFA'];

export const STATUS = {
  FALSE: 'false',
  TRUE: 'true'
};

export const DateFormats = {
  DDMMMYYY: 'DD MMM YYYY'
};

export const ERROR_STATUS_CODE = {
  ONB_1038: 'ONB-1038',
  ONB_1008: 'ONB-1008'
};

export const FILE_SIZE = {
  FOUR_MB_IN_BYTES: 4194304
};

export const REQUIRED_LENGTH = {
  OTP_LENGTH: 6,
  PASSWORD_LENGTH: 10,
  ALLOWED_DOCUMENT_LENGTH: 94
};

export const STRING_MAX_CHAR = {
  NINE: 9,
  TEN: 10,
  TWENTY: 20,
  TWENTY_TWO: 22,
  TWENTY_THREE: 23,
  TWENTY_EIGHT: 28,
  TWENTY_FIVE: 25,
  TWENTY_NINE: 29,
  THIRTY_FIVE: 35
};

export const PASSWORD_TYPE = {
  password: 'password',
  confirmPassword: 'confirmPassword'
};

export const EXTERNAL_LINKS = {
  APPLE_STORE: 'https://apps.apple.com/us/app/statrys/id1590487745',
  GOOGLE_STORE: 'https://play.google.com/store/apps/details?id=com.statrys.statrys',
  TRUST_PILOT: 'https://www.trustpilot.com/review/statrys.com',
  SENDGRID_CONTACTS: 'https://api.sendgrid.com/v3/marketing/contacts',
  COMPANY_CREATION_CALENDAR: ' https://calendly.com/incorporation/discovery-company-creation-call',
  HONG_KONG_CALENDAR: 'https://calendly.com/incorporation/onboarding-call-company-creation',
  SINGAPORE_CALENDAR: 'https://calendly.com/incorporation/onboarding-call-company-creation-singapore'
};

export const CHAR_CODE = {
  ENTER_KEY_CODE: 13
};

export const INVOICE_TYPE = {
  FROM: 'From',
  TO: 'To'
};

export const SVG_TYPES = {
  DASHBOARD: 'DASHBOARD',
  MULTIPLEUSER: 'MULTIPLEUSER',
  ACCOUNTINVOICE: 'ACCOUNTINVOICE',
  CASHPAYMENT: 'CASHPAYMENT',
  PROFILE: 'PROFILE',
  SETTING: 'SETTING',
  LOGOUT: 'LOGOUT',

  ADD_CIRCLE: 'ADD_CIRCLE',
  SWITCH_TO_STATRYS: 'SWITCH_TO_STATRYS',
  BUSINESS_ACCOUNT: 'BUSINESS_ACCOUNT',
  INVOICING: 'INVOICING',
  INCORPORATION: 'INCORPORATION',
  ARROW_DOWN: 'ARROW_DOWN',
  ARROW_UP: 'ARROW_UP',
  ARROW_DOWN_BOLD: 'ARROW_DOWN_BOLD',
  GRAY_ADD_CIRCLE: 'GRAY_ADD_CIRCLE'
};
export const INVOICE_FONTS = {
  HKGROTESK_BOLD: `${process.env.SITE_URL}/static/HKGrotesk-Bold-c164da0c8b51b1b3fbdc0d251c7eccd4.woff2`,
  HKGROTESK_REGULAR: `${process.env.SITE_URL}/static/HKGrotesk-Regular-24f8897e48415679153df49efb95f20a.woff2`,
  MICROSOFT: `${process.env.SITE_URL}/static/msyh-2ec463e8f26f205bde41012169417c95.ttf`,
  GOOGLE_NOTO: `${process.env.SITE_URL}/static/NotoSans-Regular-e49f7cc81283eef694a3a8c1986a681c.ttf`
};

export const PAGE_TITLE = {
  DASHBOARD: 'Dashboard',
  GET_PAID: 'Get paid',
  CREATE_INVOICE: 'Create Invoice',
  INVOICE_LIST: 'Invoices',
  EDIT_INVOICE: 'Edit Invoice',
  VIEW_INVOICE: 'View Invoice',
  ACCOUNT_SETTINGS: 'Account Settings',
  PROFILE: 'Profile',
  CONTACT: 'Contact',
  VIEW_CONTACT: 'View Contact',
  VIEW_INCOMING_INVOICE: 'View Incoming Invoices',
  IMPORTING_INVOICE: 'Importing invoices',
  EDIT_IMPORTING_INVOICE: 'Edit Importing Invoices',
  SETTINGS: 'Settings',
  PAY: 'Expenses',
  COMPANY: 'Company',
  COMPANY_PROFILE: 'Company profile',
  COMPANY_ORGANIZATION: 'Organization',
  COMPANY_MAILROOM: 'Mailroom',
  COMPANY_DOCUMENTS: 'Documents',
  COMPANY_SETTINGS: 'Company Settings'
};

export const CONTACT_TABLE_TITLE = {
  ALL: 'All',
  ACTIVE: 'Active',
  DELETED: 'Deleted',
  ONLY_CLIENTS: 'Client',
  ONLY_SUPPLIERS: 'Supplier'
};

export const INVOICE_STATUS = {
  INVOICE_TOTAL: 'All',
  INVOICE_PAID: 'Paid',
  INVOICE_UNPAID: 'Unpaid',
  INVOICE_OVERDUE: 'Overdue',
  INVOICE_DRAFT: 'Draft',
  UPLOADED: 'Uploaded',
  AWAITING_VALIDATION: 'Awaiting',
  PARTIALLY_PAID: 'Partially paid',
  VALID: 'Valid',
  INVALID: 'Invalid',
  NONE: 'None',
  VALIDATION: 'Validation',
  INVOICE_PARTIAL_PAID: 'Partial Paid',
  INVOICE_REVIEW: 'Review'
};

export const COMPANY_STATUS = {
  COMPANY_SECRETARY: 'Company Secretary',
  MAIL: 'Mail',
  SHAREHOLDER: 'Shareholder',
  DIRECTOR: 'Director',
  CORPORATE_SHAREHOLDER: 'Corporate shareholder'
};

export const PAYMENT_STATUS_INVOICE = {
  PAID: 'paid',
  PARTIAL_PAID: 'partialpaid',
  UNPAID: 'unpaid'
};

export const CONTACT_INFO = {
  WHATSAPP_LINK: 'https://wa.me/85264523564',
  TELEPHONE_LINK: 'tel:+852 5803 2818',
  SUPPORT_EMAIL: 'mailto:incorporation@statrys.com'
};

export const PREFERED_DUE_DATE_DURATION = [{ name: 30 }, { name: 60 }, { name: 90 }];

export const INVOICE_STATUS_SELECT = [
  { name: INVOICE_STATUS.INVOICE_DRAFT },
  { name: INVOICE_STATUS.INVOICE_UNPAID },
  { name: INVOICE_STATUS.INVOICE_PAID }
];

export const DENSE_HEIGHT_SIZE = {
  NORMAL: '56',
  MEDIUM: '76'
};

export const ROW_PER_PAGE_OPTIONS = {
  FIVE: '5',
  TEN: '10',
  TWENTY_FIVE: '25',
  FIFTY: '50'
};

export const REQUIRED_FILE_SIZE = {
  ONE_MB: 1024
};

export const TYPE_OF_OTP = {
  SIGN_UP: 1,
  FORGOT_PASSWORD: 2,
  RESEND_CODE: 3
};

export const TYPE_OF_INVOICE = {
  INVOICE: 1,
  IMPORTING_INVOICE: 2
};

export const STATUS_COLORS = {
  STATRYS_CORAL: MUI_COLORS.CORAL,
  STATRYS_YELLOW: MUI_COLORS.SUNGLOW,
  LIGHT_GREEN: MUI_COLORS.LIGHT_GREEN,
  GREEN: MUI_COLORS.GREEN,
  STATRYS_RED: MUI_COLORS.SUNSET_ORANGE,
  LIGHT_RED: MUI_COLORS.LIGHT_CREAM,
  STATRYS_GREY: '#637381',
  BLUE: MUI_COLORS.BLUE,
  LIGHT_BLUE: '#E6EFFA',
  GREY: '#D9D9D9',
  BACKGROUND_GREY: '#F9F9F9',
  CORAL_PINK: MUI_COLORS.CORAL_PINK,
  MALVA: '#F1E6FD'
};

export const EMITTER_CONSTANTS = {
  INPUT_FROM_MAIN: 'INPUT_FROM_MAIN'
};

export const API_ERROR_MESSAGES = {
  DEFAULT: 'Something went wrong'
};

export const SOCIAL_SHARE_LINK = {
  FACEBOOK_URL: 'https://www.facebook.com/sharer/sharer.php?u=',
  TELEGRAM_URL: 'https://t.me/share/url?url=',
  WHATSAPP_URL: 'https://api.whatsapp.com/send?text='
};

export const CONFIDENCE_TYPE = {
  HIGH: 1,
  LOW: 0.7,
  VERY_LOW: 0.5
};
export const SETTING_TABS_LABEL = {
  PROFILE: 'Profile',
  SECURITY: 'Security',
  PREFERENCES: 'Preferences',
  COMPANY_DETAILS: 'Company details',
  PLANS_LIMITS: 'Plans and limits',
  LEGAL_DOCUMENTS: 'Legal documents',
  COMPANY_SETTINGS: 'Company Settings'
};

export const COMPANY_TABS = {
  PROFILE: 'Profile',
  ORGANIZATION: 'Organization',
  MAILROOM: 'Mailroom',
  COMPANY_SECRETARY: 'Company Secretary',
  REQUESTS: 'Requests'
};

export const EXTERNAL_PAYMENT_LINKS = {
  PAYPAL_LINK: 'https://paypal.me/',
  PAYME_HSBC_LINK: 'https://payme.hsbc/'
};
export const SENDGRID_DETAILS = {
  LIST_IDS: '9a63f38f-6b80-4b13-8d99-e72d748171d8',
  TOKEN: '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'
};

export const EMAIL_JS = {
  PUBLIC_KEY: 'Ndc-RofFsjZvXX_2O',
  INVOICING: 'noreply_new',
  EMAIL_SUPPORT: 'email-support'
};

export const ENVIRONMENT_VARIABLES = {
  QA: 'qa',
  PRODUCTION: 'production',
  DEVELOPMENT: 'development'
};

export const LOGIN_TAB = {
  BUSINESS_ACCOUNT: 'BUSINESS_ACCOUNT',
  INVOICE: 'INVOICE',
  COMPANY_MANAGEMENT: 'COMPANY_MANAGEMENT'
};

export const COMPANY_SETUP_STEP = {
  START: 1,
  EMAIL: 2,
  VERIFY_OTP: 3,
  START_COMPANY_INFORMATION: 4,
  COMPANY_NAME: 5,
  INDUSTRY: 6,
  BUSINESS_DESCRIPTION: 7,
  OWNERS_AND_DIRECTOR_SELECTOR: 8,
  PASSPORT_UPLOAD: 9,
  PERSONAL_INFORMATION: 10,
  RESIDENTIAL_ADDRESS_INFORMATION: 11,
  BUSINESS_AND_EXPERIENCE_DETAILS: 12,
  OWNER_AND_DIRECTOR_LIST: 13,
  CAPITAL_OF_THE_COMPANY: 14,
  SIGN: 15,
  STEP_COMPLETE_SUCCESS: 16
};
export const CONSTANT_NUMBER = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  THIRTEEN: 13,
  FOURTEEN: 14
};

export const COMPANY_SETUP_SIDEBAR_STEP = {
  COMPANY_INFORMATION: 1,
  OWNERS_AND_DIRECTORS: 2,
  SIGN: 3
};

export const COMPANY_SETUP_SIDEBAR_SUB_STEP = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3
};

export const COMPANY_DESCRIPTION_STEP = {
  DESCRIPTION: 1,
  COUNTRIES: 2,
  TURNOVER: 3
};

export const MAX_LENGTH = {
  FIRST_NAME: 3,
  LAST_NAME: 2
};

export const OWNER_DIRECTOR_TYPES = {
  OWNER: 'OWNER',
  DIRECTOR: 'DIRECTOR',
  OWNER_DIRECTOR: 'OWNER_DIRECTOR'
};

export const EXPERIENCE_VALUE = {
  ZERO: '0',
  ONE: '1',
  TWO_TO_FIVE: '2 to 5',
  FIVE_TO_TEN: '5 to 10',
  TEN_PLUS: '10+'
};

export const BUSINESS_EXPERIENCE = {
  BUSINESS_OWNER: 'Business owner',
  EMPLOYEE: 'Employee',
  CONSULTANT: 'Consultant',
  FREELANCER: 'Freelancer'
};

export const REGISTERED_COUNTRY = {
  HONGKONG: 'HongKong',
  SINGAPORE: 'Singapore'
};

export const OFFICE_ADDRESS = {
  HONG_KONG_ADDRESS: 'Unit B, 11/F, Yam Tze Commercial Building, 23 Thomson Road, Wan Chai, Hong Kong',
  SINGAPORE_ADDRESS: '7 Temasek Boulevard, #12-07 \nSuntec Tower One, Singapore 038987'
};

export const RESPONSIVENESS_BREAKPOINT_VALUE = {
  MEDIUM: 767,
  SMALL: 400
};

export const REDIRECTION_SOURCE = {
  PAYMENT_APP: 'PAYMENT_APP',
  INVOICE_APP: 'INVOICE_APP',
  MOBILE_APP: 'MOBILE_APP',
  COMPANY_MANAGEMENT_APP: 'COMPANY_MANAGEMENT_APP',
  MARKETING_APP: 'MARKETING_APP'
};

export const REDIRECT_SCREEN_INFO = {
  MY_PROFILE: 'MY_PROFILE',
  UNDER_REVIEW: 'UNDER_REVIEW',
  APPROVED: 'APPROVED',
  UNFINISHED: 'UNFINISHED'
};

export const COMPANY_INCORPORATION_STATUS = {
  UNDER_REVIEW: 'UNDER_REVIEW',
  APPROVED: 'APPROVED',
  UNFINISHED: 'UNFINISHED',
  EXPIRING_SOON: 'EXPIRING_SOON'
};

export const BASE_API_URL_V1 = process.env.BASE_API_URL;
export const BASE_API_URL_V2 = process.env.BASE_API_URL_V2;
export const IS_SSO_ON = JSON.parse(process.env.SSO_ON);
export const SSO_BASE_API_URL = IS_SSO_ON ? BASE_API_URL_V2 : BASE_API_URL_V1;

export const MARKETING_LOGIN_URL = `${process.env.MARKETING_SITE_URL}/login`;

export const COMPARISON_CONSTANT = {
  LOGOUT: 'Logout'
};

export const SESSION_STORAGE_NAME = {
  COMPLETE_AUTH_INFO: 'completeAuthInfo',
  REDIRECTION_SOURCE: 'redirection_source'
};

export const CLIENT_APPLICATION_STATUS_DATA = [
  { status: 'UNDER_REVIEW', displayValue: 'Under Review', backgroundColor: '#e6f2f1' },
  { status: 'UNFINISHED', displayValue: 'Unfinished Application', backgroundColor: '#f9dbd1' }
];

export const SSO_SITE_AUTHENTICATION_URL = process.env.MARKETING_SITE_URL + '/authenticate-user';
export const ONBOARDING_BASE_REDIRECTION_URL =
  process.env.BUSINESS_ACCOUNT_ONBOARDING_SITE_URL + '/onboarding/account-setup';
export const PAYMENT_APP_APPROVED_APPLICATION_URL = process.env.PAYMENT_APP_BASE + '/authenticateUser';
export const SSO_RESET_PASSWORD_URL = process.env.MARKETING_SITE_URL + '/reset-password';

export const MAX_FILE = {
  TEN_MB_SIZE: 10 * 1024 * 1024,
  ONE_MB_SIZE: 1 * 1024 * 1024,
  TEN_PAGES: 10
};

export const EVENT_NAME = {
  TOKEN_UPDATE: 'TOKEN_UPDATE',
  DOWNLOAD_DOCUMENTS: 'DOWNLOAD_DOCUMENTS',
  USER_LOGOUT: 'USER_LOGOUT'
};

export const CONTACT_US = process.env.MARKETING_SITE_URL + '/contact';

export const USER_APP_IDENTIFIER = {
  PAYMENT_APP: '2225e6a3-a8d0-49e1-b37f-926ce798a1bc',
  MARKETING_APP: '51ee6ec6-72da-4dab-ade2-07f467b9bf7b',
  COMPANY_MANAGEMENT_APP: 'b5cd9d02-0185-40a1-9cf9-b4b1eee3c69e',
  INVOICING_APP: 'c7c9493e-b4c9-4718-8d92-be1f8797c8a8',
  MOBILE_APP: '6986a8ae-cf09-4f82-9bc1-1839accb3f63'
};
export const USER_APP_ACCESS_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
};
