/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/
 */

import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import '../styles/layout.scss';
import NavigationBar from './sideBar/NavigationBar';
import { getUser } from './authService/auth';
import MobileNavigation from './Navigation/MobileNavigation';
import Banner from '../components/common/Banner';
import { useMediaQuery, useTheme } from '@mui/material';
const Layout = ({ children, hideSideBar }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const isIncorporation = getUser()?.isIncorporation;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Banner />

      {isMobile && <MobileNavigation children={children} />}

      {!isMobile && <NavigationBar children={children} hideSideBar={hideSideBar} />}
    </>
  );
};

export default Layout;
