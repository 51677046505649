import React, { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
import { isBrowser, setCompleteAuthInSessionStorage } from '../helper/helpers';
import * as LoginActions from '../components/login/reduxStore/action';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  API_CODE,
  EVENT_NAME,
  IS_SSO_ON,
  MARKETING_LOGIN_URL,
  REDIRECTION_SOURCE,
  SESSION_STORAGE_NAME
} from '../constants/enum';
import StatrysLoader from '../components/ui/loaders/StatrysLoader';
import { handleLogin, isLoggedIn } from '../components/authService/auth';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../routes/paths';
import Seo from '../components/seo';

function WelcomeFlowInvoice({ regenerateToken }) {
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const token = params.get('token');
  const source = params.get('source');
  const [isLoading, setIsLoading] = useState(false);

  if (isBrowser() && source === REDIRECTION_SOURCE.MOBILE_APP) {
    isBrowser() && sessionStorage.setItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE, source);
  }

  const updateTokenHandler = async (token) => {
    setIsLoading(true);
    const regenerateTokenResponse = await regenerateToken(token);
    if (regenerateTokenResponse?.status === API_CODE.STATUS_200) {
      const completeAuthInfo = regenerateTokenResponse?.data?.data;
      isBrowser() && sessionStorage.setItem('token', completeAuthInfo?.token);
      isBrowser() && sessionStorage.setItem('userCode', completeAuthInfo?.userCode);
      setCompleteAuthInSessionStorage(completeAuthInfo);
      if (isLoggedIn()) {
        navigate(PATH_PAGE.start, {
          state: {
            isSSO: true
          }
        });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        navigate(MARKETING_LOGIN_URL);
      }
      if (
        isBrowser() &&
        sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) === REDIRECTION_SOURCE.MOBILE_APP
      ) {
        const data = {
          event: EVENT_NAME.TOKEN_UPDATE,
          data: completeAuthInfo
        };
        isBrowser() && window.ReactNativeWebView.postMessage(JSON.stringify(data));
      }
    } else {
      setIsLoading(false);
      navigate(MARKETING_LOGIN_URL);
    }
  };

  useEffect(() => {
    if (token && IS_SSO_ON) {
      updateTokenHandler(token);
    } else {
      navigate(MARKETING_LOGIN_URL);
    }
  }, []);

  return (
    <>
      <Seo title="Welcome flow - Invoicing | Statrys" />
      {isLoading && IS_SSO_ON ? (
        <div className="flex justify-center items-center w-full h-screen">
          <StatrysLoader />
        </div>
      ) : null}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    regenerateToken: (payload) => dispatch(LoginActions.regenerateToken(payload))
  };
};

export default connect(null, mapDispatchToProps)(WelcomeFlowInvoice);

WelcomeFlowInvoice.propTypes = {
  regenerateToken: PropTypes.func
};
