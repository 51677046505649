import { Matching401ApiErrorStatus, isBrowser } from '../../helper/helpers';
import axios from 'axios';
import { navigate } from 'gatsby';
import Emitter from '../../service/emitter';
import {
  EMITTER_CONSTANTS,
  API_ERROR_MESSAGES,
  API_ERROR_CODES,
  STATUS,
  SESSION_STORAGE_NAME,
  REDIRECTION_SOURCE,
  EVENT_NAME
} from '../../constants/enum';
import { PATH_PAGE } from '../../routes/paths';

const isMobileApp =
  isBrowser() && sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) === REDIRECTION_SOURCE.MOBILE_APP;

const instance = axios.create({
  baseURL: process.env.MARKETING_BASE_API_URL
});
instance.interceptors.request.use(
  async (config) => {
    const token = await sessionStorage.getItem('token');
    if (token && token?.length > 0) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (err) => {
    return err;
  }
);

const responseHandler = (response) => {
  return response;
};
instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => {
    if (process.env.MAINTENANCE_FLOW_ENABLED === STATUS.TRUE) {
      if (typeof window !== `undefined`) {
        navigate(PATH_PAGE.underMaintenance);
      }
    }
    if (Matching401ApiErrorStatus(error)) {
      if (isMobileApp) {
        const data = {
          event: EVENT_NAME.USER_LOGOUT,
          data: null
        };
        isBrowser() && window.ReactNativeWebView.postMessage(JSON.stringify(data));
        return;
      }
      sessionStorage.removeItem('token');
      navigate(PATH_PAGE?.login);
    } else {
      if (error?.response?.data?.statusCode !== API_ERROR_CODES?.STATUS_405) {
        Emitter.emit(EMITTER_CONSTANTS.INPUT_FROM_MAIN, {
          snackbarState: true,
          errorCode: `${error?.response?.data?.statusCode} - ${
            error?.response?.data?.error ? error?.response?.data?.error : API_ERROR_MESSAGES.DEFAULT
          }`,
          errorMessage: error?.response?.data?.message
        });
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
