export const INVOICES_API = 'example/api';
export const ENDPOINTS = {
  GET_INVOICE_DETAILS: `invoice/detail`,
  GET_INVOICE_LIST: `invoice/list`,
  SIGNUP: 'invoice/signUp',
  SSO_SIGNUP: 'invoice/users',
  LOGIN: 'invoice/login',
  CONFIRM_EMAIL: 'invoice/confirm_email',
  VERIFY_OTP: 'invoice/verifyOtp',
  SSO_VERIFY_OTP: 'invoices/users/verify-otp',
  FORGOT_PASSWORD: 'invoice/forgotPassword',
  RESET_PASSWORD: 'invoice/resetPassword',
  RESEND_OTP: 'invoice/resendOtp',
  CREATE_CONTACT: 'contact/create',
  CONTACT_LIST: 'contact/list',
  ALL_CONTACTS: '/invoice/allContacts',
  EDIT_CONTACT: 'contact/update',
  DELETE_CONTACT: 'contact/delete',
  UPLOAD: 'uploadFile',
  PUBLIC_DOCUMENT_UPLOAD: 'invoice/upload-public-documents',
  VIEW: 'contact/detail',
  UPDATE_COMPANY_DETAILS: 'invoice/updateCompanyDetails',
  USER_PROFILE: 'invoice/userProfile',
  USER_PROFILE_INFO: 'users/user-by-user-code',
  CHANGE_PASSWORD: 'invoice/changePassword',
  COMPANY_DETAILS: 'invoice/companyDetails',
  CREATE_INVOICE: 'invoice/create',
  DELETE_INVOICE: 'invoice/delete',
  EDIT_INVOICE: 'invoice/edit',
  GET_PUBLIC_INVOICE: 'invoice',
  SEND_EMAIL_INVOICE: 'invoice/sendEmail',
  PUBLIC_URL_EMAIL: '/invoice/publicUrlEmail',
  INVOICE_COUNT: '/invoice/count',
  CURRENCY_PREFERENCE: '/invoice/userSettings',
  INVOICE_UPLOAD_PDF: 'invoiceUploadPdf',
  CREATE_SUPPLIER: 'supplier/create',
  UPDATE_SUPPLIER: 'supplier/update',
  DELETE_SUPPLIER: 'supplier/delete',
  GET_SUPPLIER_BY_ID: 'supplier/detail',
  GET_SUPPLIER_LIST: 'supplier/list',
  INVOICE_IN_CREATE: 'invoiceIn/create',
  INVOICE_IN_EDIT: 'invoiceIn/edit',
  INVOICE_IN_DELETE: 'invoiceIn/delete',
  INVOICE_IN_GET_DETAILS: 'invoiceIn/detail',
  INVOICE_IN_PUBLIC: 'invoiceIn',
  INVOICE_IN_SEND_VALIDATE_EMAIL: 'invoiceIn/sendValidateEmail',
  INVOICE_IN_VALIDATORS_LIST: 'invoiceIn/validatorsList',
  INVOICE_IN_MARK_AS_PAID: 'invoiceIn/markAsPaid',
  UPDATE_MARK_AS_PAID: 'invoiceIn/editPayment',
  DELETE_MARK_AS_PAID: 'invoiceIn/deletePayment',
  GET_MARK_AS_PAID: 'invoiceIn/getPayment',
  DASHBOARD_DATA: '/invoice/dashboardData',
  GET_INVOICE_IN_LIST: '/invoiceIn/list',
  INVOICE_IN_DASHBOARD_DATA: 'invoice/dashboardData',
  ADD_BANK_DETAILS: 'bankDetails/create',
  GET_BANK_DETAILS_LIST: 'bankDetails/list',
  DELETE_BANK_DETAILS: 'bankDetails/delete',
  GET_BANK_DETAILS: 'bankDetails/data',
  EDIT_BANK_DETAILS: 'bankDetails/update',
  INVOICE_IN_SEND_EMAIL: '/invoiceIn/sendEmail',
  INVOICE_IN_VALIDATE: 'invoiceIn/validate',
  TEMP_INVOICE_CREATE: 'tempInvoice/create',
  TEMP_INVOICE_UPDATE_USER: 'tempInvoice/updateUser',
  TEMP_GET_INVOICE_DATA: 'tempInvoice',
  INVOICE_MARK_PAYMENT: 'invoice/markAsPaid',
  INVOICE_DELETE_MARK_PAYMENT: 'invoice/deletePayment',
  INVOICE_UPDATE_STATUS: 'invoice/editInvoiceStatus',
  INVOICE_PUBLIC_MARK_PAYMENT: 'invoice/markAsPaidPublic',
  INVOICE_APPROVE_PAYMENT: 'invoice/approvePayment',
  INVOICE_UPDATE_MARK_PAYMENT: 'invoice/editPayment',
  DASHBOARD_INVOICE_PERFORMANCE: 'invoice/performance',
  GET_COMPANY_REGISTRATION_DETAILS: 'company/companyBackOfficeDetails',
  BACK_OFFICE_SIGN_UP: 'backOfficeInvoice/signUp',
  VALIDATE_COMPANY_USER: 'backOfficeInvoice/validateCompanyUser',
  COMPANY_STACKHOLDERS_DETAILS: 'company/companyStackHolderDetails',
  COMPANY_DOCUMENTS_DETAILS: 'company/companyDocumentDetails',
  COMPANY_LATEST_UPDATES: 'company/companyLatestDocmentDetails',
  COMPANY_SECRETARY_DETAILS: 'company/companySecretaryDocumentDetails',
  PUBLIC_SENDGRID_SENDEMAIL: 'public/sendGrid/sendEmail',
  COMPANY_SECRETARY_SHARE: 'company/secretaryDocumentShareDetail',
  COMPANY_MAILROOM_SHARE: 'company/documentShareDetail',
  CHECK_SEND_EMAIL: '/invoice/checkerSendEmail',
  CHECK_VALIDATE_SEND_EMAIL: '/invoice/checkerValidateSendEmail',
  PASSPORT_UPLOAD: 'get-passport-data',
  GET_COMPANY_SET_UP_DETAILS: 'company/company-details-incorporation',
  GET_COMPANY_SET_UP_UPDATE: 'company/updateCompanyByIncorporation',
  COMPANY_SIGNUP: 'invoice/signupIncorporationViaBackOffice',
  COMPANY_VERIFY_OTP: 'invoice/verifyIncorporationUserOtpViaBackOffice',
  PREVIEW_DOCUMENT: 'invoice/preview-invoice-document',
  UPLOAD_DOCUMENT: '/invoice/upload-invoice-document',
  SIGNED_PDF_URL: '/invoiceIn/download-signedURL-pdf',
  UPDATE_SSO_INVOICE_TOKEN: 'invoice/users/token',
  INVOICE_COMPANIES: 'invoice-companies',
  INCORPORATION_COMPANIES: 'incorporation/companies',
  UPSERT_USER: 'invoice/upsert-user',
  REGENERATE_TOKEN: 'invoice/users/regenerate-token'
};

export const SUCCESSFULL_SIGNUP = {
  HUBSPOT_URL: 'https://api.hsforms.com/submissions/v3/integration/submit/8462932/6247a595-30cc-4bdf-8451-e12f5b56e24d'
};
