import React, { useEffect, useState } from 'react';
import PrimaryButton from '../components/ui/buttons/PrimaryButton';
import { PATH_PAGE } from '../routes/paths';
import TrashIcon from '../images/icons/trash-icon.svg';
import Layout from '../components/layout';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { API_CODE, MUI_COLORS, PAGE_TITLE, RESPONSIVENESS_BREAKPOINT_VALUE } from '../constants/enum';
import IncomingInvoiceDetails from '../components/IncomingInvoices/IncomingInvoiceDetails';
import IncomingPayments from '../components/IncomingInvoices/IncomingPayments';
import '../styles/pages/incoming-invoices.scss';
import DownloadIcon from '../images/icons/download-icon.svg';
import ReplyIcon from '../images/icons/reply-icon.svg';
import EditIcon from '../images/icons/coral-edit-icon.svg';
import { useLocation } from '@reach/router';
import { connect } from 'react-redux';
import * as importingInvoiceReducer from '../components/importedInvoices/reduxStore/action';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import StatrysLoader from '../components/ui/loaders/StatrysLoader';
import useWindowDimensions from '../hooks/useWindowDimensions';
import DownloadDocument from '../components/common/DownloadDocument';
import Seo from '../components/seo';

const ViewIncomingInvoices = ({ deleteImportedInvoice, getImportedInvoice }) => {
  const [trigger, setTrigger] = useState(0);
  const [importedInvoiceData, setImportedInvoiceData] = useState('');
  const [isFetchingInvoice, setIsFetchingInvoice] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get('id');
  const { width } = useWindowDimensions();

  const handleDeleteSupplierInvoice = async () => {
    const deletedInvoiceResponse = await deleteImportedInvoice(id);
    if (deletedInvoiceResponse?.status === API_CODE.STATUS_200) {
      navigate(PATH_PAGE.pay);
    }
  };

  const getImportingInvoiceDetails = async (importedInvoiceId) => {
    setIsFetchingInvoice(true);
    const getImportedInvoicesResponse = await getImportedInvoice(importedInvoiceId);
    if (getImportedInvoicesResponse?.status === API_CODE.STATUS_200) {
      setImportedInvoiceData(getImportedInvoicesResponse?.data?.data);
      setIsFetchingInvoice(false);
    }
  };

  const handleDownloadInvoice = () => {};

  useEffect(() => {
    getImportingInvoiceDetails(id);
  }, []);
  const updateInvoiceAfterCancelValidation = () => {
    getImportingInvoiceDetails(id);
  };
  return (
    <Layout>
      <Seo
        title="Incoming Invoice - Invoicing | Statrys"
        description="Create, edit and send your invoices and get paid faster with Statrys."
      />
      <HeaderBreadcrumbs
        subtitleClass={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'mb-2' : 'mb-0'}
        titleContainerClass={{
          flexDirection: ['column', 'row', 'row'],
          alignItems: ['left', 'center', 'center'],
          width: '100%'
        }}
        sx={{ height: ['100%', '56px', '56px'], display: 'flex', alignItems: 'center' }}
        heading={PAGE_TITLE.VIEW_INCOMING_INVOICE}
        links={
          width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
            ? [
                { name: PAGE_TITLE.DASHBOARD, href: PATH_PAGE.dashboard },
                { name: PAGE_TITLE.PAY, href: PATH_PAGE.pay },
                { name: `${importedInvoiceData?.invoiceNumber}` }
              ]
            : [{ name: '' }]
        }
        action={
          <div className="flex flex-row gap-2">
            <PrimaryButton
              id="view-incoming-invoice-edit-btn"
              urlLink={PATH_PAGE.editImportingInvoice + `?id=${id}`}
              bgColor="bg-white"
              caption={
                <div className="w-4">
                  <img src={EditIcon} alt="edit icon" />
                </div>
              }
              type="small"
            />
            <PrimaryButton
              id="view-incoming-invoice-share-btn"
              bgColor="bg-white"
              caption={
                <div className="w-4">
                  <img src={ReplyIcon} alt="Share" />
                </div>
              }
              type="small"
              onClick={() => {
                setTrigger((trigger) => trigger + 1);
              }}
            />
            <DownloadDocument
              invoiceId={importedInvoiceData?._id}
              documentName={importedInvoiceData?.invoiceNumber}
              buttonData={{
                id: 'public-importing-invoice-download-pdf-btn',
                bgColor: 'bg-white',
                textColor: '',
                className: '',
                caption: (
                  <div>
                    <img src={DownloadIcon} alt="downloadIcon" />
                  </div>
                )
              }}
            />
            <PrimaryButton
              id="view-incoming-invoice-delete-btn"
              bgColor="bg-white"
              caption={
                <div>
                  <img src={TrashIcon} alt="Delete" />
                </div>
              }
              onClick={handleDeleteSupplierInvoice}
              type="small"
            />
          </div>
        }
      />
      {!importedInvoiceData ? (
        <div className="flex justify-center items-center">
          <StatrysLoader />
        </div>
      ) : (
        <div className="flex md:flex-row flex-col items-start gap-10">
          <IncomingInvoiceDetails importedInvoiceData={importedInvoiceData} />
          <IncomingPayments
            trigger={trigger}
            isFetchingInvoice={isFetchingInvoice}
            importedInvoiceData={importedInvoiceData}
            getImportingInvoiceDetails={() => getImportingInvoiceDetails(id)}
            cancelValidationSuccess={updateInvoiceAfterCancelValidation}
          />
        </div>
      )}
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteImportedInvoice: (invoicePayload) => dispatch(importingInvoiceReducer.deleteImportedInvoice(invoicePayload)),
    getImportedInvoice: (invoiceId) => dispatch(importingInvoiceReducer.getImportingInvoiceDetails(invoiceId))
  };
};

export default connect(null, mapDispatchToProps)(ViewIncomingInvoices);

ViewIncomingInvoices.propTypes = {
  deleteImportedInvoice: PropTypes.func,
  getImportedInvoice: PropTypes.func
};
