import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor/index';
import MarketingAPI from '../../../store/Interceptor/marketingInterceptor';
import axios from 'axios';
import { modifyData } from '../../../helper/helpers';

export const updateCompanyDetailsRequestStart = () => ({ type: actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_START });
export const updateCompanyDetailsRequestFail = (error) => ({
  type: actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_FAIL,
  error: error
});
export const updateCompanyDetailsRequestSuccess = (companyDetails) => ({
  type: actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_SUCCESS,
  companyDetails: companyDetails
});
export const updateCompanyDetails = (companyDetailsPayload) => {
  return async (dispatch) => {
    try {
      dispatch(updateCompanyDetailsRequestStart());
      let companyDetailsResponse = await Api.put(ENDPOINTS.UPDATE_COMPANY_DETAILS, companyDetailsPayload);
      dispatch(updateCompanyDetailsRequestSuccess(companyDetailsResponse));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(updateCompanyDetailsRequestFail(error.response));
    }
  };
};

export const createCompanyDetailsRequestStart = () => ({ type: actionTypes.CREATE_COMPANY_DETAILS_REQUEST_START });
export const createCompanyDetailsRequestFail = (error) => ({
  type: actionTypes.CREATE_COMPANY_DETAILS_REQUEST_FAIL,
  error: error
});
export const createCompanyDetailsRequestSuccess = (companyDetails) => ({
  type: actionTypes.CREATE_COMPANY_DETAILS_REQUEST_SUCCESS,
  companyDetails: companyDetails
});
export const createCompanyDetails = (companyDetailsPayload) => {
  return async (dispatch) => {
    try {
      dispatch(createCompanyDetailsRequestStart());
      let companyDetailsResponse = await Api.post(ENDPOINTS.COMPANY_DETAILS, companyDetailsPayload);
      dispatch(createCompanyDetailsRequestSuccess(companyDetailsResponse));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(createCompanyDetailsRequestFail(error.response));
    }
  };
};

export const getCompanyDetailsRequestStart = () => ({ type: actionTypes.GET_COMPANY_DETAILS_REQUEST_START });
export const getCompanyDetailsRequestFail = (error) => ({
  type: actionTypes.GET_COMPANY_DETAILS_REQUEST_FAIL,
  error: error
});
export const getCompanyDetailsRequestSuccess = (companyDetails) => ({
  type: actionTypes.GET_COMPANY_DETAILS_REQUEST_SUCCESS,
  getCompanyDetails: companyDetails
});
export const getCompanyDetails = () => {
  return async (dispatch) => {
    try {
      dispatch(getCompanyDetailsRequestStart());
      let companyDetailsResponse = await Api.get(ENDPOINTS.COMPANY_DETAILS);
      dispatch(getCompanyDetailsRequestSuccess(companyDetailsResponse));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(getCompanyDetailsRequestFail(error.response));
    }
  };
};

export const invoiceCompanyListRequestStart = () => ({ type: actionTypes.INVOICE_COMPANY_LIST_REQUEST_START });
export const invoiceCompanyListRequestFail = (error) => ({
  type: actionTypes.INVOICE_COMPANY_LIST_REQUEST_FAIL,
  error: error
});
export const invoiceCompanyListRequestSuccess = (companyListDetails) => ({
  type: actionTypes.INVOICE_COMPANY_LIST_REQUEST_SUCCESS,
  invoiceCompanyList: companyListDetails
});
export const invoiceCompanyList = () => {
  return async (dispatch) => {
    try {
      dispatch(invoiceCompanyListRequestStart());
      let companyDetailsResponse = await Api.get(ENDPOINTS.INVOICE_COMPANIES);
      dispatch(invoiceCompanyListRequestSuccess(companyDetailsResponse));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(invoiceCompanyListRequestFail(error.response));
    }
  };
};

export const incorporationCompanyListRequestStart = () => ({
  type: actionTypes.INCORPORATION_COMPANY_LIST_REQUEST_START
});
export const incorporationCompanyListRequestFail = (error) => ({
  type: actionTypes.INCORPORATION_COMPANY_LIST_REQUEST_FAIL,
  error: error
});
export const incorporationCompanyListRequestSuccess = (companyListDetails) => ({
  type: actionTypes.INCORPORATION_COMPANY_LIST_REQUEST_SUCCESS,
  incorporationCompanyList: companyListDetails
});
export const incorporationCompanyList = () => {
  return async (dispatch) => {
    try {
      dispatch(incorporationCompanyListRequestStart());
      let companyDetailsResponse = await Api.get(ENDPOINTS.INCORPORATION_COMPANIES);
      dispatch(incorporationCompanyListRequestSuccess(companyDetailsResponse));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(incorporationCompanyListRequestFail(error.response));
    }
  };
};

export const businessAccountCompanyListRequestStart = () => ({
  type: actionTypes.BUSINESS_COMPANY_LIST_REQUEST_START
});
export const businessAccountCompanyListRequestFail = (error) => ({
  type: actionTypes.BUSINESS_COMPANY_LIST_REQUEST_FAIL,
  error: error
});
export const businessAccountCompanyListRequestSuccess = (companyListDetails) => ({
  type: actionTypes.BUSINESS_COMPANY_LIST_REQUEST_SUCCESS,
  businessAccountCompanyList: companyListDetails
});
export const businessAccountCompanyList = (userCode) => {
  const BUSINESS_COMPANIES = `client-management/v1/users/${userCode}/accounts`;

  return async (dispatch) => {
    try {
      dispatch(businessAccountCompanyListRequestStart());
      let companyDetailsResponse = await MarketingAPI.get(`${BUSINESS_COMPANIES}`);
      const {
        businessAccounts = [],
        cardAccounts = [],
        prospectAccounts = [],
        invitationAccounts = [],
        cardApplicationAccounts = []
      } = companyDetailsResponse?.data;
      let allApplications = [
        ...businessAccounts,
        ...cardAccounts,
        ...prospectAccounts,
        ...invitationAccounts,
        ...cardApplicationAccounts
      ];
      allApplications = modifyData(allApplications);
      dispatch(businessAccountCompanyListRequestSuccess(allApplications));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(businessAccountCompanyListRequestFail(error.response));
    }
  };
};

export const applicationAccessListRequestStart = () => ({
  type: actionTypes.APPLICATION_ACCESS_REQUEST_START
});
export const applicationAccessListRequestFail = (error) => ({
  type: actionTypes.APPLICATION_ACCESS_REQUEST_FAIL,
  error: error
});
export const applicationAccessListRequestSuccess = (companyListDetails) => ({
  type: actionTypes.APPLICATION_ACCESS_REQUEST_SUCCESS,
  applicationAccessList: companyListDetails
});
export const applicationAccessList = (userCode) => {
  const BUSINESS_COMPANIES = `user-identity-management/v1/users/${userCode}/application-access`;

  return async (dispatch) => {
    try {
      dispatch(applicationAccessListRequestStart());
      let companyDetailsResponse = await MarketingAPI.get(`${BUSINESS_COMPANIES}`);
      dispatch(applicationAccessListRequestSuccess(companyDetailsResponse));
      return companyDetailsResponse;
    } catch (error) {
      dispatch(applicationAccessListRequestFail(error.response));
    }
  };
};
