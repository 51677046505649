import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MSText from '../ui/typography/MSText';
import { connect } from 'react-redux';
import { INVOICE_STATUS, MUI_COLORS } from '../../constants/enum';
import { useLocation } from '@reach/router';
import XSText from '../ui/typography/XSText';
import ArrowDownIcon from '../../images/icons/arrow-down-menu.svg';

function NavigationDropDown({ menuItems, selectedMenu, setSelectedMenu, isDisabled }) {
  const [onHover, setOnHover] = useState();

  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const id = params.get('id');

  const handleToUpdate = (menuItem) => {
    setSelectedMenu(menuItem);
  };

  return (
    <>
      <div className="flex items-center item-menu-dropdown dropdown">
        <div className={`flex flex-row gap-2 items-center ${isDisabled && 'cursor-pointer'}`}>
          <div className="invoice-status-container flex" style={{ backgroundColor: MUI_COLORS.EXTRA_LIGHT_GRAY }}>
            <XSText fontWeight="text-bold" lineHeight="line-height-18" textColor="text-gray-500" title={selectedMenu} />
            <img src={ArrowDownIcon} className="ml-2" width="8" height="8" alt="Arrow down" />
          </div>
          {isDisabled && (
            <div className="status-selector-dropdown-container">
              <div className="status-selector-dropdown-content">
                <div className="status-selector-tooltip-container">
                  <div className="tooltip-icon"></div>
                </div>
                <div
                  className="status-selector-dropdown-content-container rounded-xl flex flex-col p-2 h-80 overflow-auto"
                  onMouseLeave={() => setOnHover()}
                >
                  {menuItems?.map((menu, index) => {
                    return (
                      <>
                        <div
                          onMouseEnter={() => setOnHover()}
                          onClick={() => handleToUpdate(menu)}
                          className={`flex flex-row items-center rounded-lg p-3 ${
                            index === onHover ? 'bg-gray-100 ' : ''
                          }`}
                          key={index}
                        >
                          <div>{menu?.icon}</div>
                          <MSText title={menu?.name} textColor="text-gray-300" className="dropdown-email-text ml-2" />
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(null, mapDispatchToProps)(NavigationDropDown);

NavigationDropDown.propTypes = {
  menuItems: PropTypes.array,
  editInvoice: PropTypes.func,
  invoiceDetailsByID: PropTypes.object,
  handleInvoiceDetailsbyID: PropTypes.func
};
