import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { connect } from 'react-redux';
import * as profileActions from '../profile/reduxStore/action';
import PropTypes from 'prop-types';
import { API_CODE, IS_SSO_ON, MUI_COLORS, PREFERED_DUE_DATE_DURATION } from '../../constants/enum';
import Snackbar from '../Snackbar';
import Success from '../../images/icons/success-icon.svg';
import Image from '../Image';
import XSText from '../ui/typography/XSText';
import MSText from '../ui/typography/MSText';
import SelectInputAutoComplete from '../ui/inputs/selectInputAutoComplete';
import InvoiceCurrenciesList from '../../data/invoice-currencies-list.json';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import BaseText from '../ui/typography/BaseText';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import { Autocomplete, TextField } from '@mui/material';

function SettingProfileForm({ currencyPreference, getCurrencyPreference }) {
  const { register, handleSubmit, errors } = useForm();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [currency, setCurrency] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);

  const onSubmitCurrencyDetails = async () => {
    setButtonLoader(true);
    const payload = {
      currency: currency
    };
    const currencyDataResponse = await currencyPreference(payload);
    if (currencyDataResponse?.status === API_CODE.STATUS_200) {
      setMessage({
        ...message,
        contentMessage: `Currency Preference Saved Successfully`,
        messageTitle: 'Success'
      });
      setCurrency(currencyDataResponse?.data?.data?.currency);
      setOpen(true);
      setButtonLoader(false);
    } else {
      setOpen(false);
      setButtonLoader(false);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getPreferedCurrency = async () => {
    const getCurrencyDataResponse = await getCurrencyPreference();
    if (getCurrencyDataResponse?.status === API_CODE.STATUS_200) {
      setCurrency(getCurrencyDataResponse?.data?.data?.currency);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  const setPreferedDueDate = (value) => {};

  useEffect(() => {
    getPreferedCurrency();
  }, []);

  return (
    <>
      <Snackbar
        open={open}
        handleClose={handleClose}
        sx={{ bgcolor: MUI_COLORS.LIGHT_GREEN, border: '1px solid', borderColor: MUI_COLORS.GREEN }}
        title={
          <div className="flex">
            <div className="flex items-center">
              <Image className="w-5 h-5" src={Success} />
            </div>
            <div className="ml-4">
              <XSText fontWeight="text-bold" textColor="text-gray-500" title={message?.messageTitle}></XSText>
              <MSText textColor="text-gray-500" title={message?.contentMessage}></MSText>
            </div>
          </div>
        }
      ></Snackbar>
      {isLoading ? (
        <div className="h-full flex items-center">
          <StatrysLoader />
        </div>
      ) : (
        <>
          <div className="setting-form-container md:ml-6 bg-white px-6 pb-8">
            <MDSubtitleText
              className="py-6"
              fontWeight="text-bold"
              textColor="text-gray-500"
              title="Preferences" // Note: Will update this to company settings
            />
            <BaseText
              textColor="text-gray-450"
              className="mb-2"
              fontSize="text-base"
              title="The currency used by default for your account"
            />
            <div className="mb-4 w-full">
              <SelectInputAutoComplete
                name="preferredCurrency"
                id="currency-preference"
                disabled={false}
                variant="filled"
                defaultValue={currency}
                value={currency}
                setSelectedValue={setCurrency}
                disableClearable={true}
                inputRef={register({
                  required: false
                })}
                options={InvoiceCurrenciesList}
                label="Currency"
              />
            </div>

            {IS_SSO_ON && (
              <div className="flex justify-start gap-4 mt-6">
                <PrimaryButton
                  id="currency-preferences-save-changes-btn"
                  className="setting-section-button"
                  isLoading={buttonLoader}
                  caption="Save"
                  onClick={handleSubmit(onSubmitCurrencyDetails)}
                />
              </div>
            )}
          </div>

          {!IS_SSO_ON && (
            <div className="flex justify-end gap-4 mt-6">
              <PrimaryButton
                id="currency-preferences-save-changes-btn"
                className="setting-section-button"
                isLoading={buttonLoader}
                caption="Save Changes"
                onClick={handleSubmit(onSubmitCurrencyDetails)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    currencyPreference: (payload) => dispatch(profileActions.currencyPreference(payload)),
    getCurrencyPreference: (payload) => dispatch(profileActions.getCurrencyPreference(payload))
  };
};

export default connect(null, mapDispatchToProps)(SettingProfileForm);
SettingProfileForm.propTypes = {
  currencyPreference: PropTypes.func
};
