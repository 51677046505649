import * as actionTypes from './actionTypes';
import { ENDPOINTS } from '../../../service/services';
import Api from '../../../store/Interceptor';
import { IS_SSO_ON } from '../../../constants/enum';

export const signUpData = (signUpData) => ({
  type: actionTypes.SIGNUP_DATA_REQUEST_SUCCESS,
  signUpData: signUpData
});

export const signupRequestStart = () => ({ type: actionTypes.SIGNUP_REQUEST_START });
export const signupRequestFail = (error) => ({ type: actionTypes.SIGNUP_REQUEST_FAILURE, error: error });
export const signupRequestSuccess = (signupPayload) => ({
  type: actionTypes.SIGNUP_REQUEST_SUCCESS,
  signupPayload: signupPayload
});
export const signup = (signupPayload) => {
  return async (dispatch) => {
    try {
      dispatch(signupRequestStart());
      let signupResponse = await Api.post(IS_SSO_ON ? ENDPOINTS.SSO_SIGNUP : ENDPOINTS.SIGNUP, signupPayload);
      dispatch(signupRequestSuccess(signupResponse?.data));
      return signupResponse;
    } catch (error) {
      dispatch(signupRequestFail(error.response));
      return error?.response;
    }
  };
};

export const marketingSignup = (signupPayload) => {
  return async (dispatch) => {
    dispatch(signupRequestSuccess({ data: signupPayload }));
  };
};

export const companySignupRequestStart = () => ({ type: actionTypes.COMPANY_SIGNUP_REQUEST_START });
export const companySignupRequestFail = (error) => ({ type: actionTypes.COMPANY_SIGNUP_REQUEST_FAILURE, error: error });
export const companySignupRequestSuccess = (companySignupPayload) => ({
  type: actionTypes.COMPANY_SIGNUP_REQUEST_SUCCESS,
  companySignupPayload: companySignupPayload
});

export const companySignup = (companySignupPayload) => {
  return async (dispatch) => {
    try {
      dispatch(companySignupRequestStart());
      let companySignupResponse = await Api.post(ENDPOINTS.COMPANY_SIGNUP, companySignupPayload);
      dispatch(companySignupRequestSuccess(companySignupResponse?.data));
      return companySignupResponse;
    } catch (error) {
      dispatch(companySignupRequestFail(error.response));
      return error?.response;
    }
  };
};

export const upsertUserRequestStart = () => ({ type: actionTypes.UPSERT_USER_REQUEST_START });
export const upsertUserRequestFail = (error) => ({ type: actionTypes.UPSERT_USER_REQUEST_FAILURE, error: error });
export const upsertUserRequestSuccess = (upsertUserPayload) => ({
  type: actionTypes.UPSERT_USER_REQUEST_SUCCESS,
  upsertUserPayload: upsertUserPayload
});

export const upsertUser = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(upsertUserRequestStart());
      let upsertUserResponse = await Api.post(ENDPOINTS.UPSERT_USER, payload);
      dispatch(upsertUserRequestSuccess(upsertUserResponse?.data));
      return upsertUserResponse;
    } catch (error) {
      dispatch(upsertUserRequestFail(error.response));
      return error?.response;
    }
  };
};
