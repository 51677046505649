import React, { useEffect, useState } from 'react';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import GreenStudyLightIcon from '../../../images/icons/bulb.svg';
import XSText from '../../ui/typography/XSText';
import UploadImage from '../../common/UploadImage';
import * as commonReduxActions from '../../commonReduxStore/reduxStore/action';
import { connect } from 'react-redux';
import { FILE_UPLOAD_TYPE } from '../../../constants/enum';
import ValidationMessage from '../../ui/ValidationMessage';

function UploadCompanyLogo({ uploadContactImage, handleCustomData, companyDetailsData, logoError, setLogoError }) {
  const [open, setOpen] = useState(false);
  const [saveImage, setSaveImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    companyDetailsData.logoPublic ? [{ preview: companyDetailsData?.logoPublic, type: FILE_UPLOAD_TYPE.URL }] : ''
  );
  const [uploadError, setUploadError] = useState(false);
  const [acceptedFile, setAcceptedFile] = useState('');
  const fileSelection = async (acceptedFiles) => {
    setAcceptedFile(acceptedFiles);
    let selectedimg = acceptedFiles?.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setSelectedImage(selectedimg);
    setSaveImage(true);
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    const uploadedProfileResponse = await uploadContactImage(formData);
    handleCustomData({ logoPublic: uploadedProfileResponse?.data?.data?.Location });
    setLogoError(false);
  };

  return (
    <div className="modal-step-container flex flex-col justify-center">
      <MDSubtitleText
        className="md:whitespace-pre-line mb-6"
        fontWeight="text-bold"
        title={`Last, but not least, upload your logo!`}
      />
      <UploadImage
        saveImage={saveImage}
        selectedImage={selectedImage}
        uploadError={uploadError}
        fileSelection={fileSelection}
      />
      {logoError && (
        <div className="flex justify-center">
          <ValidationMessage textColor="text-coral-500" title="Please upload file to continue" />
        </div>
      )}
      <div className="flex gap-4 bg-green-200 p-4 items-start rounded-lg mt-2">
        <img src={GreenStudyLightIcon} alt="GreenStudyLightIcon" />
        <XSText title="The logo will be used on the head of the invoice to show your brand." />
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    uploadContactImage: (payload) => dispatch(commonReduxActions.publicDocumentUpload(payload))
  };
};

export default connect(null, mapDispatchToProps)(UploadCompanyLogo);
