import React from 'react';
import Invoice from '../images/graphics/invoice.png';
import XSText from '../components/ui/typography/XSText';
import H4HeaderText from '../components/ui/typography/H4HeaderText';
import BaseText from '../components/ui/typography/BaseText';
import GreenCheck from '../images/icons/check-circle-green.svg';
import PropTypes from 'prop-types';
import StatrysLogo from '../images/icons/statrysLogo.svg';
import '../styles/verify-email.scss';
import { Link } from 'gatsby';
import SignupForm from '../components/signup/SignupForm';
import Seo from '../components/seo';
import { IS_SSO_ON, MARKETING_LOGIN_URL } from '../constants/enum';
import { PATH_PAGE } from '../routes/paths';

export default function SignUp() {
  return (
    <>
      <Seo title="Signup - Invoicing | Statrys" description="Sign up for a Statrys Invoicing Account." />
      <div className="bg-customBg-secondaryBg verify-email-parent-container relative overflow-hidden">
        <div className="verify-email-blur"></div>
        <div className="absolute flex justify-between md:w-screen items-center md:p-8 p-4 gap-4">
          <img src={StatrysLogo} width="146.24" height="40" alt="statrys-logo" />
          <XSText
            textAlign="md:text-left text-right"
            title={
              <div>
                Already have an account?{' '}
                <Link className="text-coral-500" to={IS_SSO_ON ? MARKETING_LOGIN_URL : PATH_PAGE.login}>
                  Log in
                </Link>
              </div>
            }
          />
        </div>
        <div className="flex flex-col md:flex-row gap-8 items-center self-center justify-center md:h-screen">
          <SignupForm />
          <div className="px-10 py-8 w-full">
            <H4HeaderText title="Statrys Invoicing" />
            <BaseText className="my-4" title="100% free online invoicing software" />
            <div className="flex items-start justify-start gap-4">
              <img src={GreenCheck} className="w-4 h-4" alt="GreenCheck" />
              <div>
                <XSText title="Simplify your Invoicing Process" />
                <XSText
                  className="mt-1 md:whitespace-pre-line"
                  textColor="text-gray-450"
                  title={`Create, duplicate and edit invoices in a few \nclicks.`}
                />
              </div>
            </div>
            <div className="flex gap-4 mt-6">
              <img src={GreenCheck} className="w-4 h-4" alt="GreenCheck" />
              <div>
                <XSText title="Get paid faster" />
                <XSText
                  className="mt-1 md:whitespace-pre-line"
                  textColor="text-gray-450"
                  title={`Include payment links and get notified when \nyour clients open your invoices.`}
                />
              </div>
            </div>
            <div className="flex gap-4 mt-6">
              <img src={GreenCheck} className="w-4 h-4" alt="GreenCheck" />
              <div>
                <XSText title="Be in control" />
                <XSText
                  className="mt-1 md:whitespace-pre-line"
                  textColor="text-gray-450"
                  title={`Cut down time spent on invoice \nmanagement, track your invoices and \nmonitor your financial performance.`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

SignUp.propTypes = {
  location: PropTypes.any
};
