import React from 'react';
import Layout from '../components/layout';
import HeaderBreadcrumbs from '../components/HeaderBreadcrumbs';
import { IS_SSO_ON, PAGE_TITLE, RESPONSIVENESS_BREAKPOINT_VALUE, STATUS } from '../constants/enum';
import Page from '../components/Page';
import SettingTabs from '../components/setting/SettingTabs';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { PATH_PAGE } from '../routes/paths';
import Seo from '../components/seo';

export default function Profile() {
  const { width } = useWindowDimensions();

  return (
    <Layout>
      <Seo
        title="Settings - Invoicing | Statrys"
        description="Create, edit and send your invoices and get paid faster with Statrys."
      />
      <Page title="Invoice: settings">
        <HeaderBreadcrumbs
          subtitleClass={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'mb-0' : 'mb-2'}
          heading={IS_SSO_ON ? PAGE_TITLE.COMPANY_SETTINGS : PAGE_TITLE.SETTINGS}
          links={
            width > RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM
              ? [{ name: PAGE_TITLE.DASHBOARD, href: PATH_PAGE.dashboard }, {}]
              : [{ name: '' }]
          }
        />
        <SettingTabs />
      </Page>
    </Layout>
  );
}
