import React from 'react';
import TextInput from '../../ui/inputs/TextInput';
import MDSubtitleText from '../../ui/typography/MDSubtitleText';
import ValidationMessage from '../../ui/ValidationMessage';

function AboutYourself({ register, errors, companyDetailsData }) {
  return (
    <div className="modal-step-container flex flex-col justify-center">
      <MDSubtitleText className="md:whitespace-pre-line mb-6" fontWeight="text-bold" title={`Tell us about yourself`} />
      <div className="flex gap-2">
        <TextInput
          className="mt-2 w-full"
          name="firstName"
          inputRef={register({
            required: true
          })}
          defaultValue={companyDetailsData?.firstName}
          error={errors?.firstName}
          helperText={errors?.firstName && <ValidationMessage title="Please select a valid First name" />}
          label="Given name"
        />
        <TextInput
          className="mt-2 w-full"
          name="lastName"
          inputRef={register({
            required: true
          })}
          defaultValue={companyDetailsData?.lastName}
          error={errors?.lastName}
          helperText={errors?.lastName && <ValidationMessage title="Please select a valid Last name" />}
          label="Surname"
        />
      </div>
    </div>
  );
}

export default AboutYourself;
