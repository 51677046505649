import React, { useState, useEffect } from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import WelcomeToStatrys from './CompanyDetailsModal/WelcomeToStatrys';
import BusinessOfCompany from './CompanyDetailsModal/BusinessOfCompany';
import '../../styles/company-details-modal.scss';
import BackButton from '../ui/buttons/BackButton';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import StepsIndicator from '../ui/StepsIndicator';
import AboutYourBusiness from './CompanyDetailsModal/AboutYourBusiness';
import CompanyName from './CompanyDetailsModal/CompanyName';
import CompanyAddress from './CompanyDetailsModal/CompanyAddress';
import UploadCompanyLogo from './CompanyDetailsModal/UploadCompanyLogo';
import Thankyou from './CompanyDetailsModal/Thankyou';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import * as accountSettingActions from '../accountSetting/reduxStore/action';
import * as commonReduxActions from '../commonReduxStore/reduxStore/action';
import * as invoiceActions from '../../components/invoice/reduxStore/action';
import PreferredCurrency from './CompanyDetailsModal/PreferredCurrency';
import { navigate } from 'gatsby';
import { PATH_PAGE } from '../../routes/paths';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import { isBrowser, openExternalLink, sendHubspotRequest } from '../../helper/helpers';
import { SUCCESSFULL_SIGNUP } from '../../service/services';
import { useLocation } from '@reach/router';
import * as SignUpActions from '../signup/reduxStore/action';
import * as LoginActions from '../login/reduxStore/action';
import { API_CODE, EVENT_NAME, IS_SSO_ON, REDIRECTION_SOURCE, SESSION_STORAGE_NAME } from '../../constants/enum';
import AboutYourself from './CompanyDetailsModal/AboutYourself';

function CompanyDetailsModal({
  updateCompanyDetails,
  getCompanyDetails,
  getInvoiceList,
  createCompanyDetails,
  isModal,
  upsertUser,
  updateSSOToken,
  getProfile
}) {
  const [showModal, setShowModal] = useState(false);
  const [companyDetailsData, setCompanyDetailsData] = useState({});
  const [needToCreateCompany, setNeedToCreateCompany] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const location = useLocation();
  const { state } = location;
  const token = isBrowser() && sessionStorage.getItem('token');
  const identifierCode = isBrowser() && sessionStorage.getItem('userCode');
  const [logoError, setLogoError] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    formState: { errors }
  } = useForm();
  const nextStep = (value) => {
    setCompanyDetailsData({ ...companyDetailsData, ...value });
    setStep(step + 1);
  };
  const YOURSELF = 'YOURSELF';
  const handleCustomData = (companyDetail) => {
    setCompanyDetailsData({ ...companyDetailsData, ...companyDetail });
  };
  const userEmail = typeof window !== 'undefined' && JSON.parse(localStorage.getItem('user'));

  const selectedStep = () => {
    switch (step) {
      case 0:
        return <WelcomeToStatrys />;
      case YOURSELF:
        return <AboutYourself companyDetailsData={companyDetailsData} errors={errors} register={register} />;
      case 1:
        return (
          <BusinessOfCompany
            control={control}
            register={register}
            setValue={setValue}
            errors={errors}
            data={companyDetailsData}
            clearErrors={clearErrors}
          />
        );

      case 2:
        return (
          <AboutYourBusiness
            setValue={setValue}
            nextStep={nextStep}
            companyDetailsData={companyDetailsData}
            errors={errors}
          />
        );
      case 3:
        return <CompanyName companyDetailsData={companyDetailsData} errors={errors} register={register} />;
      case 4:
        return (
          <CompanyAddress
            companyDetailsData={companyDetailsData}
            control={control}
            register={register}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
          />
        );
      case 5:
        return <PreferredCurrency setStep={setStep} nextStep={nextStep} />;
      case 6:
        return (
          <UploadCompanyLogo
            companyDetailsData={companyDetailsData}
            handleCustomData={handleCustomData}
            setValue={setValue}
            logoError={logoError}
            setLogoError={setLogoError}
          />
        );
      case 7:
        return <Thankyou />;
      default:
        return <WelcomeToStatrys />;
    }
  };
  const onSubmit = (datas) => {
    setCompanyDetailsData({ ...companyDetailsData, ...datas });
    if (!isModal) {
      if (step === 0 && !getProfile?.firstName && !getProfile?.lastName) {
        setStep(YOURSELF);
      } else if (step === YOURSELF) {
        setStep(1);
      } else if (step === 0 && (getProfile?.firstName || getProfile?.lastName)) {
        setStep(1);
      } else if (step === 6 && !companyDetailsData?.logoPublic) {
        setLogoError(true);
      } else if (step === 6 && companyDetailsData?.logoPublic) {
        setCompanyDetailsData({ ...companyDetailsData, isWelcomeFlowCompleted: true });
        setStep(step + 1);
      } else if (step === 7) {
        handleCloseDynamicModal();
      } else {
        setStep(step + 1);
      }
    } else {
      if (step === 6) {
        setCompanyDetailsData({ ...companyDetailsData, isWelcomeFlowCompleted: true });
      }
      if (step === 7) {
        handleCloseDynamicModal();
      } else {
        setStep(step + 1);
      }
    }
  };
  const handleBackButton = () => {
    if (step === 1 && !getProfile?.firstName && !getProfile?.lastName) {
      setStep(YOURSELF);
    } else if (step === YOURSELF) {
      setStep(0);
    } else {
      setStep(step - 1);
    }
  };
  async function sendHubspot(email) {
    let hubspotPayload = {
      fields: [
        {
          name: 'email',
          value: userEmail?.email
        },
        {
          name: 'product_usage',
          value: 'Invoicing Software'
        },
        {
          name: 'industry',
          value: companyDetailsData?.industries?.length && companyDetailsData?.industries[0]
        },
        {
          name: 'company',
          value: companyDetailsData?.companyName
        }
      ],
      context: {
        pageUri: `${process.env.SITE_URL}/dashboard`,
        pageName: 'Welcome Invoice Modal'
      }
    };
    var finalData = JSON.stringify(hubspotPayload);
    sendHubspotRequest(finalData, SUCCESSFULL_SIGNUP?.HUBSPOT_URL, true);
  }
  const handleCloseDynamicModal = async () => {
    setIsLoading(true);
    step > 2 && !IS_SSO_ON && sendHubspot();
    if (isModal) {
      if (needToCreateCompany) {
        companyDetailsData?.companyName && (await createCompanyDetails({ ...companyDetailsData }));
      } else {
        companyDetailsData?.companyName && (await updateCompanyDetails({ ...companyDetailsData }));
      }
      setShowModal(false);
      setIsLoading(false);
      if (step === 7) {
        navigate(PATH_PAGE?.createInvoice);
      }
    } else {
      const upsertUserResponse = await upsertUser({ ...companyDetailsData });
      if (upsertUserResponse?.status === API_CODE.STATUS_200) {
        if (step === 7) {
          openExternalLink(
            `${process.env.SITE_URL}/login?identifierCode=${upsertUserResponse?.data?.data?.companyId}&token=${token}&source=${REDIRECTION_SOURCE.INVOICE_APP}`
          );
          isBrowser() && sessionStorage.removeItem('token');
          setCompanyDetailsData('');
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (isModal) {
      handleModalOnRender();
    }
  }, []);
  const handleModalOnRender = async () => {
    const companyDetailsResponse = await getCompanyDetails();
    const companyDetail = companyDetailsResponse?.data?.data;
    delete companyDetail?._id;
    delete companyDetail?.__v;
    delete companyDetail?.isDeleted;
    delete companyDetail?.updatedAt;
    delete companyDetail?.userId;
    delete companyDetail?.ownerId;
    delete companyDetail?.createdAt;
    setNeedToCreateCompany(companyDetail?.companyName ? false : true);
    setCompanyDetailsData(companyDetail);
    const invoiceListDetails = await getInvoiceList();
    if (!companyDetail?.isWelcomeFlowCompleted && !invoiceListDetails?.data?.data?.totalCount) {
      setShowModal(true);
    }
  };

  const handleRedirectionToDashboard = () => {
    openExternalLink(
      `${process.env.MARKETING_SITE_URL}/authenticate-user?token=${token}&identifierCode=${identifierCode}&source=${REDIRECTION_SOURCE.INVOICE_APP}&redirectTo=DASHBOARD`
    );
  };

  const createUserAndCompany = async () => {
    const upsertUserResponse = await upsertUser({ ...companyDetailsData });
    const companyId = upsertUserResponse?.data?.data?.companyId;
    isBrowser() && sessionStorage.setItem('companyId', companyId);
    setCompanyDetailsData({ ...companyDetailsData, invoiceCompanyId: companyId });
    const payload = {
      companyId: companyId
    };
    const updateSSOTokenResponse = await updateSSOToken(payload, token);
    if (updateSSOTokenResponse?.status === API_CODE.STATUS_200) {
      isBrowser() && sessionStorage.setItem('token', updateSSOTokenResponse?.data?.data?.token);
      if (
        isBrowser() &&
        sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) === REDIRECTION_SOURCE.MOBILE_APP
      ) {
        const data = {
          event: EVENT_NAME.TOKEN_UPDATE,
          data: updateSSOTokenResponse?.data?.data
        };
        isBrowser() && window.ReactNativeWebView.postMessage(JSON.stringify(data));
      }
    }
  };

  useEffect(() => {
    if (step === 0) {
      navigate('?step=welcome-to-statrys');
    }
    if (step === 1) {
      navigate('?step=business-of-company');
    }
    if (step === YOURSELF) {
      navigate('?step=about-yourself');
    }
    if (step === 2) {
      navigate('?step=about-your-business');
    }
    if (step === 3) {
      navigate('?step=company-name');
    }
    if (step === 4) {
      navigate('?step=company-address');
      !isModal && createUserAndCompany();
    }
    if (step === 5) {
      navigate('?step=preferred-currency');
    }
    if (step === 6) {
      navigate('?step=upload-logo');
    }
    if (step === 7) {
      navigate('?step=thankyou');
    }
  }, [step]);
  return (
    <>
      {isModal ? (
        <DynamicModal
          openDynamicModal={showModal}
          closeDynamicModal={() => handleCloseDynamicModal()}
          maxWidth="md"
          hideCrossIcon={true}
        >
          {step && step < 6 ? (
            <div className="mt-1">
              <StepsIndicator numberOfSteps={6} currentStepNumber={step} />
            </div>
          ) : null}
          {isLoading ? (
            <div className="modal-step-container flex items-center">
              <StatrysLoader />{' '}
            </div>
          ) : (
            selectedStep()
          )}
          {step !== 5 && (
            <div className="flex mt-4 justify-between">
              <BackButton onClick={() => (step === 0 ? handleCloseDynamicModal() : setStep(step - 1))} />
              <div className="flex flex-col-reverse items-end md:flex-row gap-2">
                {(step === 4 || step === 5 || step === 6) && (
                  <PrimaryButton
                    className="company-details-modal-button border border-gray-400"
                    bgColor="bg-white"
                    onClick={() => setStep(step + 1)}
                    isBorderRequired={true}
                    color="text-gray-300"
                    caption="Skip for now"
                  />
                )}
                {step !== 2 && (
                  <PrimaryButton
                    onClick={handleSubmit(onSubmit)}
                    className="company-details-modal-button"
                    caption={step == 7 ? 'Create an invoice' : 'Continue'}
                  />
                )}
              </div>
            </div>
          )}
        </DynamicModal>
      ) : (
        <div className="bg-white p-8 rounded-xl box-shadow">
          {isLoading ? (
            <div className="modal-step-container flex items-center">
              <StatrysLoader />{' '}
            </div>
          ) : (
            <>
              {step && step < 6 ? (
                <div className="mt-1">
                  <StepsIndicator numberOfSteps={6} currentStepNumber={step} />
                </div>
              ) : null}
              {isLoading ? (
                <div className="modal-step-container flex items-center">
                  <StatrysLoader />{' '}
                </div>
              ) : (
                selectedStep()
              )}
              {step !== 5 && (
                <div className="flex mt-4 justify-between">
                  <BackButton onClick={() => (step === 0 ? handleRedirectionToDashboard() : handleBackButton())} />
                  <div className="flex flex-col-reverse items-end md:flex-row gap-2">
                    {(step === 4 || step === 5 || step === 6) && (
                      <PrimaryButton
                        className="company-details-modal-button border border-gray-400"
                        bgColor="bg-white"
                        onClick={() => setStep(step + 1)}
                        isBorderRequired={true}
                        color="text-gray-300"
                        caption="Skip for now"
                      />
                    )}
                    {step !== 2 && (
                      <PrimaryButton
                        onClick={handleSubmit(onSubmit)}
                        className="company-details-modal-button"
                        caption={step == 7 ? 'Go to Dashboard' : 'Continue'}
                      />
                    )}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getProfile: state?.userProfieReducer?.getUserProfileDetails?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompanyDetails: (companyDetailsPayload) =>
      dispatch(accountSettingActions.updateCompanyDetails(companyDetailsPayload)),
    createCompanyDetails: (companyDetailsPayload) =>
      dispatch(accountSettingActions.createCompanyDetails(companyDetailsPayload)),
    getCompanyDetails: () => dispatch(accountSettingActions.getCompanyDetails()),
    uploadContactImage: (payload) => dispatch(commonReduxActions.fileUpload(payload)),
    getInvoiceList: () => dispatch(invoiceActions.getInvoiceList()),
    upsertUser: (payload) => dispatch(SignUpActions.upsertUser(payload)),
    updateSSOToken: (payload, token) => dispatch(LoginActions.updateSSOToken(payload, token))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsModal);
