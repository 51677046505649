import React, { useEffect, useRef, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FooterLogo from '../../images/icons/footer-statrys-logo.svg';
import StatrysSupport from '../common/statrysSupport/StatrysSupport';
import { AppBar, Toolbar, Typography } from '@mui/material';
import { Link, navigate } from 'gatsby';
import {
  MUI_COLORS,
  STRING_MAX_CHAR,
  SVG_TYPES,
  REDIRECTION_SOURCE,
  IS_SSO_ON,
  REDIRECT_SCREEN_INFO,
  SSO_SITE_AUTHENTICATION_URL,
  SESSION_STORAGE_NAME
} from '../../constants/enum';
import { INVOICE_DASHBOARD, INVOICE_LIST, PATH_PAGE, IMPORTING_INVOICE, COMPANY } from '../../routes/paths';
import Avatar from '../ui/Avatar';
import {
  clearBrowserStorageAndRedirect,
  getAvatarText,
  getProfileName,
  getSecondLastPathName,
  getURLFirstPathName,
  isBrowser,
  makeFirstLetterCapitalize,
  stringTruncate
} from '../../helper/helpers';
import MSText from '../ui/typography/MSText';
import { useLocation } from '@reach/router';
import { getUser, logOut, setUser } from '../../components/authService/auth';
import { connect } from 'react-redux';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import * as accountSettingActions from '../../components/accountSetting/reduxStore/action';
import { getSvgIcon } from '../../helper/svgHelper';
import DashboardIcon from '../../images/icons/sideBar/pie-line-dashboard.svg';
import ActiveDashboardIcon from '../../images/icons/sideBar/pie-line-dashboard-active.svg';
import InvoiceIcon from '../../images/icons/sideBar/invoice-file-icon.svg';
import ActiveInvoiceIcon from '../../images/icons/sideBar/invoice-file-icon-active.svg';
import CashPaymentIcon from '../../images/icons/sideBar/cash-payment-coin.svg';
import ActiveCashPaymentIcon from '../../images/icons/sideBar/cash-payment-coin-active.svg';
import ManContactBookIcon from '../../images/icons/sideBar/man-contact-book.svg';
import ActiveManContactBookIcon from '../../images/icons/sideBar/man-contact-book-active.svg';
import BaseText from '../ui/typography/BaseText';
import HeaderMenuIcon from '../../images/icons/header/header-menu-icon.svg';
import NavigationDropDown from '../Navigation/NavigationDropDown';
import XSText from '../ui/typography/XSText';
import CompanyManagementIcon from '../../images/icons/header/company-management.svg';
import SignaturesIcon from '../../images/icons/header/signatures.svg';
import BusinessIcon from '../../images/icons/header/bussines-account.svg';
import InvoicingIcon from '../../images/icons/header/invoicing-icon.svg';
import NavigationDropDownLeft from '../Navigation/NavigationDropDownLeft';
import WrenchIcon from '../../images/icons/sideBar/wrench-icon.svg';
import ActiveWrenchIcon from '../../images/icons/sideBar/active-wrench-icon.svg';

const drawerWidth = 270;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const companyList = [
  {
    companyName: 'Company 2 and a longer name here'
  },
  {
    companyName: 'Company 3 and a longer name here'
  },
  {
    companyName: 'ACME Pacific and dragon li Limit'
  }
];

const navigation = [
  {
    id: 1,
    pageName: 'Dashboard',
    icon: DashboardIcon,
    activeIcon: ActiveDashboardIcon,
    to: INVOICE_DASHBOARD
  },
  {
    id: 2,
    pageName: 'Invoices',
    icon: InvoiceIcon,
    activeIcon: ActiveInvoiceIcon,
    to: INVOICE_LIST
  },
  {
    id: 3,
    pageName: 'Expenses',
    icon: CashPaymentIcon,
    activeIcon: ActiveCashPaymentIcon,
    to: PATH_PAGE.pay
  },
  {
    id: 4,
    pageName: 'Contacts',
    icon: ManContactBookIcon,
    activeIcon: ActiveManContactBookIcon,
    to: PATH_PAGE.contact
  }
];

const updatedNavigation = [
  ...navigation,
  {
    id: 4,
    pageName: 'Company Settings',
    icon: WrenchIcon,
    activeIcon: ActiveWrenchIcon,
    to: PATH_PAGE.profile
  }
];

const accountOptions = [
  {
    accountName: 'Invoice',
    accountIcon: InvoicingIcon,
    bgColor: 'bg-yellow-500'
  },
  {
    accountName: 'Business Account',
    accountIcon: BusinessIcon,
    bgColor: 'bg-red-500'
  },
  {
    accountName: 'Company Management',
    accountIcon: CompanyManagementIcon,
    bgColor: 'bg-green-500'
  },
  {
    accountName: 'Signatures',
    accountIcon: SignaturesIcon,
    bgColor: 'bg-blue-500'
  }
];

const profileOptions = [
  {
    pageName: 'Profile',
    icon: getSvgIcon(SVG_TYPES.PROFILE),
    to: PATH_PAGE.profile
  },
  {
    pageName: 'Account Settings',
    icon: getSvgIcon(SVG_TYPES.SETTING),
    to: PATH_PAGE.accountSettings
  },
  {
    pageName: 'Logout',
    icon: getSvgIcon(SVG_TYPES.LOGOUT),
    onMenuItemClick: logOut
  }
];

const redirectToProfile = () => {
  const token = isBrowser() && sessionStorage.getItem('token');
  const userCode = isBrowser() && sessionStorage.getItem('userCode');
  clearBrowserStorageAndRedirect(
    SSO_SITE_AUTHENTICATION_URL +
      `?identifierCode=${userCode}&token=${token}&source=${REDIRECTION_SOURCE.INVOICE_APP}&redirectTo=${REDIRECT_SCREEN_INFO.MY_PROFILE}`
  );
};

const profileOptionsSSO = [
  {
    pageName: 'My Profile',
    icon: getSvgIcon(SVG_TYPES.PROFILE),
    onMenuItemClick: redirectToProfile
  },
  {
    pageName: 'Logout',
    icon: getSvgIcon(SVG_TYPES.LOGOUT),
    onMenuItemClick: logOut
  }
];

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

function NavigationBar({
  children,
  getProfile,
  getUserProfile,
  getCompanyInfo,
  getCompanyDetails,
  hideSideBar = false
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [profileData, setProfileData] = useState({});
  const [companyFormData, setCompanyFormData] = useState();
  const user = getUser();
  const location = useLocation();
  const [allCompanies, setAllCompanies] = useState([]);
  const isMobileApp =
    isBrowser() && sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) === REDIRECTION_SOURCE.MOBILE_APP;
  const [isLeftDropDownOpen, setIsLeftDropDownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isRightDropDownOpen, setIsRightDropDownOpen] = useState(false);
  const [isChildElementHovered, setIsChildElementHovered] = useState(false);
  const rightDropdownRef = useRef(null);

  const handleClick = (value) => {
    navigate(value);
  };

  const handleActiveTab = (urlLink) => {
    const urlFirstPathName = getURLFirstPathName(location?.pathname);
    const linkFirstPath = getURLFirstPathName(urlLink);
    if (`/${urlFirstPathName}` === PATH_PAGE.company) {
      const urlSecondPathName = getSecondLastPathName(location?.pathname);
      const linkSecondPath = getSecondLastPathName(urlLink);
      if (urlSecondPathName === linkSecondPath) {
        return true;
      } else {
        return false;
      }
    } else {
      if (urlFirstPathName === linkFirstPath) {
        return true;
      } else if (
        (`/${urlFirstPathName}` === PATH_PAGE.viewIncomingInvoices || `/${urlFirstPathName}` === IMPORTING_INVOICE) &&
        urlLink === PATH_PAGE.pay
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  useEffect(() => {
    getUserProfileInfo();
    if (!IS_SSO_ON) {
      getCompanyDetailsHandler();
    }
  }, []);

  const getCompanyDetailsHandler = async () => {
    await getCompanyDetails();
  };

  const getUserProfileInfo = async () => {
    await getUserProfile();
  };

  useEffect(() => {
    setProfileData(getProfile);
    if (IS_SSO_ON) {
      const userByCode = {
        email: getProfile?.email
      };
      isBrowser() && window.localStorage.setItem('user', JSON.stringify(userByCode));
    }
    if (!IS_SSO_ON) {
      setCompanyFormData(getCompanyInfo);
    }
  }, [getProfile, getCompanyInfo]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsLeftDropDownOpen(false);
      }
    }
    if (isLeftDropDownOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isLeftDropDownOpen]);

  useEffect(() => {
    if (IS_SSO_ON) {
      function handleRightClickOutside(event) {
        if (rightDropdownRef.current && !rightDropdownRef.current.contains(event.target)) {
          setIsRightDropDownOpen(false);
        }
      }
      if (isRightDropDownOpen) {
        document.addEventListener('click', handleRightClickOutside);
      }
      return () => {
        document.removeEventListener('click', handleRightClickOutside);
      };
    }
  }, [isRightDropDownOpen]);

  const settingsTab = IS_SSO_ON ? profileOptionsSSO : profileOptions;
  const navigateMenu = IS_SSO_ON ? updatedNavigation : navigation;

  const firstName = getProfileName(profileData);

  const lastName = profileData?.firstName ? makeFirstLetterCapitalize(profileData?.lastName) : '';
  const fullName = makeFirstLetterCapitalize(firstName) + ' ' + makeFirstLetterCapitalize(lastName);
  return (
    <Box sx={{ display: 'flex' }} className="max-w-screen-2xl mx-auto">
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: 'white',
          boxShadow: 'none',
          '& .MuiToolbar-root': {
            minHeight: '66px',
            paddingLeft: '40px',
            paddingRight: '40px'
          }
        }}
      >
        {isMobileApp ? null : (
          <Toolbar className="max-w-screen-2xl w-full mx-auto">
            <div style={{ flexGrow: 1 }}>
              <div className="flex">
                <div
                  className={`flex gap-2 items-center hover:cursor-pointer`}
                  onClick={() => setIsLeftDropDownOpen((prev) => !prev)}
                  ref={dropdownRef}
                >
                  <img src={InvoicingIcon} alt="invoice management" />
                  <div>
                    <BaseText fontSize="text-xl" fontWeight="text-bold" title={'Invoicing'} />
                  </div>
                  {IS_SSO_ON && (
                    <>
                      {getSvgIcon(SVG_TYPES.ARROW_DOWN_BOLD)}
                      <NavigationDropDownLeft
                        menuItems={profileOptions}
                        isNavigation={true}
                        companyList={allCompanies}
                        isLeftDropDownOpen={isLeftDropDownOpen}
                        needSeparator={true}
                        separtorPositions={[1]}
                        className={'dropdown-content-container-left'}
                      ></NavigationDropDownLeft>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`flex gap-8 ${
                IS_SSO_ON && isChildElementHovered ? 'hover:bg-transparent cursor-pointer p-2 rounded-lg' : ''
              }
            ${IS_SSO_ON && !isChildElementHovered ? 'hover:bg-coral-150 cursor-pointer p-2 rounded-lg' : ''}`}
            >
              <div
                className={`flex items-center item-menu-dropdown ${IS_SSO_ON ? 'hover:cursor-pointer' : 'dropdown'}`}
                onClick={() => IS_SSO_ON && setIsRightDropDownOpen((prev) => !prev)}
                ref={rightDropdownRef}
              >
                <div className="flex flex-row gap-2 items-center cursor-pointer">
                  <div className="rounded-full">
                    <Avatar
                      avatarStyling={IS_SSO_ON ? 'w-8 h-8' : 'w-12 h-12'}
                      avatarImage={IS_SSO_ON ? null : companyFormData?.logoPublic}
                      avatarName={fullName ? fullName?.charAt(0) : user?.email?.charAt(0)}
                      name={firstName ? getAvatarText(firstName, lastName) : user?.email?.charAt(0)}
                      fontSize="text-base"
                    />
                  </div>
                  <div>
                    {profileData?.firstName && (
                      <MSText
                        title={stringTruncate(fullName, STRING_MAX_CHAR.TWENTY_NINE)}
                        fontWeight="text-bold"
                        textColor="text-gray-500"
                      />
                    )}
                    <MSText
                      fontWeight="text-medium"
                      title={stringTruncate(
                        user?.email
                          ? makeFirstLetterCapitalize(user?.email)
                          : makeFirstLetterCapitalize(profileData?.email),
                        STRING_MAX_CHAR.TWENTY_NINE
                      )}
                      className="menu-item-text"
                      textColor="text-gray-500"
                    />
                  </div>
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      IS_SSO_ON && setIsRightDropDownOpen((prev) => !prev);
                    }}
                  >
                    {IS_SSO_ON
                      ? isRightDropDownOpen
                        ? getSvgIcon(SVG_TYPES.ARROW_UP)
                        : getSvgIcon(SVG_TYPES.ARROW_DOWN)
                      : getSvgIcon(SVG_TYPES.ARROW_DOWN)}
                  </div>
                </div>
                <NavigationDropDown
                  menuItems={settingsTab}
                  isRightDropDownOpen={isRightDropDownOpen}
                  setIsChildElementHovered={setIsChildElementHovered}
                  isNavigation={true}
                  needSeparator={true}
                  separtorPositions={IS_SSO_ON ? '' : [0, 1]}
                >
                  {IS_SSO_ON ? (
                    <div className="mt-2"></div>
                  ) : (
                    <div className="mt-4 px-4">
                      <XSText
                        title={
                          profileData?.lastName && profileData?.firstName
                            ? stringTruncate(fullName, STRING_MAX_CHAR.THIRTY_FIVE)
                            : ''
                        }
                        fontWeight="text-bold"
                        lineHeight="leading-6"
                      />
                      <MSText
                        title={stringTruncate(
                          user?.email ? user?.email : profileData?.email,
                          STRING_MAX_CHAR.THIRTY_FIVE
                        )}
                        textColor="text-gray-450"
                        className="dropdown-email-text whitespace-nowrap overflow-hidden"
                      />
                    </div>
                  )}
                </NavigationDropDown>
              </div>
            </div>
          </Toolbar>
        )}
      </AppBar>
      {!hideSideBar && (
        <Drawer
          variant="permanent"
          open={open}
          sx={{
            '& .MuiDrawer-paper': {
              position: 'relative',
              zIndex: '100',
              backgroundColor: MUI_COLORS.LIGHT_GRAY,
              borderRight: 'none',
              paddingLeft: '32px',
              paddingRight: '32px'
            }
          }}
        >
          <div className={`flex flex-col justify-between h-full ${isMobileApp ? 'mt-4' : 'mt-20'}`}>
            <div>
              <List>
                {navigateMenu.map((navigate) => (
                  <ListItem key={navigate.id} disablePadding sx={{ display: 'block', marginBottom: '4px' }}>
                    <ListItemButton
                      sx={{
                        minHeight: 56,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        '&:hover': { backgroundColor: 'white' }
                      }}
                      className={`${handleActiveTab(navigate?.to) && 'bg-white'} rounded-lg`}
                      onClick={() => handleClick(navigate?.to)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : 'auto',
                          justifyContent: 'center'
                        }}
                      >
                        <img
                          className={`${handleActiveTab(navigate?.to) && 'text-green-500'}`}
                          src={handleActiveTab(navigate?.to) ? navigate?.activeIcon : navigate?.icon}
                          alt={navigate?.pageName}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={`${handleActiveTab(navigate?.to) && 'text-gray-500 font-bold'}`}
                        sx={{
                          opacity: open ? 1 : 0,
                          '& .MuiTypography-root': {
                            fontWeight: handleActiveTab(navigate?.to) && 700
                          }
                        }}
                      >
                        {navigate.pageName}
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </div>

            <footer className="pb-5 fixed-footer">
              <div className="flex justify-center">
                <img src={FooterLogo} alt={FooterLogo} />
              </div>
              <StatrysSupport />
            </footer>
          </div>
        </Drawer>
      )}
      <Box component="main" sx={{ flexGrow: 1, backgroundColor: MUI_COLORS.LIGHT_GRAY, height: '100vh' }}>
        <div className="layout-container pt-4 mx-auto pb-8">
          <main className={isMobileApp ? null : 'py-15'}>{children}</main>
        </div>
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return {
    getProfile: state?.userProfieReducer?.getUserProfileDetails?.data,
    getCompanyInfo: state?.accountSettings?.getCompanyDetails?.data?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    getCompanyDetails: () => dispatch(accountSettingActions.getCompanyDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
