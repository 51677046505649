import * as React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn } from '../authService/auth';
import { PATH_PAGE } from '../../routes/paths';
import { IS_SSO_ON, MARKETING_LOGIN_URL, SSO_RESET_PASSWORD_URL } from '../../constants/enum';
import { isBrowser } from '../../helper/helpers';
const PrivateRoute = ({ component: Component, ...rest }) => {
  if (isLoggedIn()) {
    navigate(PATH_PAGE.dashboard);
    return null;
  }
  const { path } = rest;
  const shouldRedirectToSSO = IS_SSO_ON && isBrowser() && [PATH_PAGE.resetPassword].includes(path);
  if (shouldRedirectToSSO) {
    navigate(SSO_RESET_PASSWORD_URL);
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
