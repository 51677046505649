import * as React from 'react';
import { navigate } from 'gatsby';
import { isLoggedIn } from '../authService/auth';
import { PATH_PAGE } from '../../routes/paths';
import { IS_SSO_ON, MARKETING_LOGIN_URL } from '../../constants/enum';
import StatrysLoader from '../ui/loaders/StatrysLoader';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== PATH_PAGE.login) {
    navigate(PATH_PAGE.login);
    return (
      <div className="w-full h-screen flex justify-center items-center">
        <StatrysLoader />
      </div>
    );
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
