export const SIGNUP_DATA_REQUEST_SUCCESS = 'SIGNUP_DATA_REQUEST_SUCCESS';

export const SIGNUP_REQUEST_START = 'SIGNUP_REQUEST_START';
export const SIGNUP_REQUEST_SUCCESS = 'SIGNUP_REQUEST_SUCCESS';
export const SIGNUP_REQUEST_FAILURE = 'SIGNUP_REQUEST_FAILURE';

export const COMPANY_SIGNUP_REQUEST_START = 'COMPANY_SIGNUP_REQUEST_START';
export const COMPANY_SIGNUP_REQUEST_SUCCESS = 'COMPANY_SIGNUP_REQUEST_SUCCESS';
export const COMPANY_SIGNUP_REQUEST_FAILURE = 'COMPANY_SIGNUP_REQUEST_FAILURE';

export const UPSERT_USER_REQUEST_START = 'UPSERT_USER_REQUEST_START';
export const UPSERT_USER_REQUEST_SUCCESS = 'UPSERT_USER_REQUEST_SUCCESS';
export const UPSERT_USER_REQUEST_FAILURE = 'UPSERT_USER_REQUEST_FAILURE';
