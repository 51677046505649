import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import InvoicingIcon from '../../images/icons/header/invoicing-icon.svg';
import { AppBar, Menu, Paper, Tabs, Toolbar, Typography } from '@mui/material';
import StatrysLogo from '../../images/icons/statrysLogo.svg';
import { useState } from 'react';
import DashboardIcon from '../../images/icons/sideBar/pie-line-dashboard.svg';
import ActiveDashboardIcon from '../../images/icons/sideBar/pie-line-dashboard-active.svg';
import InvoiceIcon from '../../images/icons/sideBar/invoice-file-icon.svg';
import ActiveInvoiceIcon from '../../images/icons/sideBar/invoice-file-icon-active.svg';
import CashPaymentIcon from '../../images/icons/sideBar/cash-payment-coin.svg';
import ActiveCashPaymentIcon from '../../images/icons/sideBar/cash-payment-coin-active.svg';
import ManContactBookIcon from '../../images/icons/sideBar/man-contact-book.svg';
import ActiveManContactBookIcon from '../../images/icons/sideBar/man-contact-book-active.svg';
import { IMPORTING_INVOICE, INVOICE_DASHBOARD, INVOICE_LIST, PATH_PAGE } from '../../routes/paths';
import BaseText from '../ui/typography/BaseText';
import { getUser, logOut } from '../authService/auth';
import MenuIcon from '../../images/icons/header/mobile-hamburger-menu.svg';
import FooterLogo from '../../images/icons/footer-statrys-logo.svg';
import StatrysSupport from '../common/statrysSupport/StatrysSupport';
import { useEffect } from 'react';
import {
  COMPARISON_CONSTANT,
  COMPARISON_CONSTANTS,
  IS_SSO_ON,
  MUI_COLORS,
  REDIRECTION_SOURCE,
  REDIRECT_SCREEN_INFO,
  SESSION_STORAGE_NAME,
  SSO_SITE_AUTHENTICATION_URL,
  STATUS,
  SVG_TYPES
} from '../../constants/enum';
import { Link, navigate } from 'gatsby';
import MSText from '../ui/typography/MSText';
import {
  clearBrowserStorageAndRedirect,
  getAvatarText,
  getProfileName,
  getSecondLastPathName,
  getURLFirstPathName,
  isBrowser
} from '../../helper/helpers';
import { useLocation } from '@reach/router';
import { getSvgIcon } from '../../helper/svgHelper';
import Avatar from '../ui/Avatar';
import * as userProfileActions from '../../components/profile/reduxStore/action';
import * as accountSettingActions from '../../components/accountSetting/reduxStore/action';
import { connect } from 'react-redux';
import NavigationDropDownLeft from './NavigationDropDownLeft';
import WrenchIcon from '../../images/icons/sideBar/wrench-icon.svg';
import ActiveWrenchIcon from '../../images/icons/sideBar/active-wrench-icon.svg';
const navigation = [
  {
    id: 1,
    pageName: 'Dashboard',
    icon: DashboardIcon,
    activeIcon: ActiveDashboardIcon,
    to: INVOICE_DASHBOARD
  },
  {
    id: 2,
    pageName: 'Invoices',
    icon: InvoiceIcon,
    activeIcon: ActiveInvoiceIcon,
    to: INVOICE_LIST
  },
  {
    id: 3,
    pageName: 'Expenses',
    icon: CashPaymentIcon,
    activeIcon: ActiveCashPaymentIcon,
    to: PATH_PAGE.pay
  },
  {
    id: 4,
    pageName: 'Contacts',
    icon: ManContactBookIcon,
    activeIcon: ActiveManContactBookIcon,
    to: PATH_PAGE.contact
  }
];
const updatedNavigation = [
  ...navigation,
  {
    id: 4,
    pageName: 'Settings',
    icon: WrenchIcon,
    activeIcon: ActiveWrenchIcon,
    to: PATH_PAGE.profile
  }
];

const redirectToProfile = () => {
  const token = isBrowser() && sessionStorage.getItem('token');
  const userCode = isBrowser() && sessionStorage.getItem('userCode');
  clearBrowserStorageAndRedirect(
    SSO_SITE_AUTHENTICATION_URL +
      `?identifierCode=${userCode}&token=${token}&source=${REDIRECTION_SOURCE.INVOICE_APP}&redirectTo=${REDIRECT_SCREEN_INFO.MY_PROFILE}`
  );
};

const profileOptions = [
  {
    pageName: 'Profile',
    icon: getSvgIcon(SVG_TYPES.PROFILE),
    to: PATH_PAGE.profile
  },
  {
    pageName: 'Account Settings',
    icon: getSvgIcon(SVG_TYPES.SETTING),
    to: PATH_PAGE.accountSettings
  },
  {
    pageName: 'Logout',
    icon: getSvgIcon(SVG_TYPES.LOGOUT),
    to: ''
  }
];

const profileOptionsSSO = [
  {
    pageName: 'My Profile',
    icon: getSvgIcon(SVG_TYPES.PROFILE),
    onMenuItemClick: redirectToProfile
  },
  {
    pageName: 'Logout',
    icon: getSvgIcon(SVG_TYPES.LOGOUT),
    onMenuItemClick: logOut
  }
];

function MobileNavigation({ children, getProfile, getCompanyInfo, getUserProfile }) {
  const [value, setValue] = useState('recents');
  const [open, setOpen] = useState(false);
  const ref = React.useRef(null);
  const location = useLocation();
  const user = getUser();
  const [profileData, setProfileData] = useState({});
  const [companyFormData, setCompanyFormData] = useState();
  const isMobileApp =
    isBrowser() && sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) === REDIRECTION_SOURCE.MOBILE_APP;

  const [isLeftDropDownOpen, setIsLeftDropDownOpen] = useState(false);
  const [isRightDropDownOpen, setIsRightDropDownOpen] = useState(false);
  const dropdownRef = React.useRef(null);

  const handleActiveTab = (urlLink) => {
    const urlFirstPathName = getURLFirstPathName(location?.pathname);
    const linkFirstPath = getURLFirstPathName(urlLink);
    if (`/${urlFirstPathName}` === PATH_PAGE.company) {
      const urlSecondPathName = getSecondLastPathName(location?.pathname);
      const linkSecondPath = getSecondLastPathName(urlLink);
      if (urlSecondPathName === linkSecondPath) {
        return true;
      } else {
        return false;
      }
    } else {
      if (urlFirstPathName === linkFirstPath) {
        return true;
      } else if (
        (`/${urlFirstPathName}` === PATH_PAGE.viewIncomingInvoices || `/${urlFirstPathName}` === IMPORTING_INVOICE) &&
        urlLink === PATH_PAGE.pay
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const navigationMenu = navigation;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  const firstName = getProfileName(profileData, user);

  const isSingleSignOn = process.env.SSO_ON === STATUS.TRUE ? profileOptionsSSO : profileOptions;
  const navigateMenu = IS_SSO_ON ? updatedNavigation : navigation;

  const handleClose = () => {
    setOpen(false);
  };

  const getUserProfileInfo = async () => {
    await getUserProfile();
  };

  useEffect(() => {
    getUserProfileInfo();
  }, []);

  useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
    setProfileData(getProfile);
    if (IS_SSO_ON) {
      const userByCode = {
        email: getProfile?.email
      };
      isBrowser() && window.localStorage.setItem('user', JSON.stringify(userByCode));
    }
    if (IS_SSO_ON) {
      setCompanyFormData(getCompanyInfo);
    } else {
      setCompanyFormData();
    }
  }, [getProfile, getCompanyInfo]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsLeftDropDownOpen(false);
        setIsRightDropDownOpen(false);
      }
    }
    if (isLeftDropDownOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    if (isRightDropDownOpen) {
      document.addEventListener('click', handleClickOutside);
    }
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isLeftDropDownOpen]);

  return (
    <div className="h-screen" ref={ref}>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: 'white',
          boxShadow: 'none',
          '& .MuiToolbar-root': {
            paddingRight: '8px',
            paddingLeft: '8px'
          }
        }}
      >
        {isMobileApp ? null : (
          <Toolbar>
            <Typography variant="h6" noWrap sx={{ flexGrow: 1, fontWeight: 400 }} component="div">
              <div className="flex">
                <div
                  className={`flex gap-2 items-center hover:cursor-pointer`}
                  onClick={() => setIsLeftDropDownOpen((prev) => !prev)}
                  ref={dropdownRef}
                >
                  <img src={InvoicingIcon} alt="invoice management" />
                  <div>
                    <BaseText fontSize="text-xl" fontWeight="text-bold" title={'Invoicing'} />
                  </div>
                  {IS_SSO_ON && (
                    <>
                      {getSvgIcon(SVG_TYPES.ARROW_DOWN_BOLD)}
                      <NavigationDropDownLeft
                        menuItems={profileOptions}
                        isNavigation={true}
                        isLeftDropDownOpen={isLeftDropDownOpen}
                        needSeparator={true}
                        separtorPositions={[1]}
                        className={'dropdown-content-container-left'}
                      ></NavigationDropDownLeft>
                      {isLeftDropDownOpen && (
                        <div
                          style={{
                            marginTop: '65px',
                            zIndex: -1,
                            position: 'fixed',
                            inset: '0px',
                            backgroundColor: MUI_COLORS.DARK_CHARCOAL,
                            opacity: '0.4',
                            backdropFilter: 'blur(8px)'
                          }}
                        ></div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Typography>
            <div className="flex gap-8">
              <div className="flex items-center item-menu-dropdown dropdown">
                <Menu
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  sx={{
                    '& .MuiModal-backdrop': {
                      top: '58px',
                      backgroundColor: `${MUI_COLORS.DARK_CHARCOAL} !important`,
                      opacity: '0.4 !important'
                    },
                    'div.MuiMenu-paper': {
                      top: '56px !important',
                      left: '0px !important',
                      right: '0px !important',
                      maxWidth: '100% !important',
                      borderRadius: '0px 0px 8px 8px'
                    }
                  }}
                  PaperProps={{
                    sx: {
                      width: '100%'
                    }
                  }}
                >
                  <div className="mx-2">
                    {isSingleSignOn?.map((menuItem, index) => {
                      return (
                        <div className="px-2 py-4 rounded-lg hover:bg-coral-150" key={index}>
                          {menuItem?.onMenuItemClick ? (
                            <div
                              className="hover:no-underline flex flex-row cursor-pointer"
                              onClick={menuItem?.onMenuItemClick}
                            >
                              <div className="py-2 pl-2">{menuItem?.icon}</div>
                              <BaseText
                                title={menuItem?.pageName}
                                fontWeight="text-semibold"
                                textColor="text-gray-500"
                                className="px-2 py-2"
                              />
                            </div>
                          ) : (
                            <Link
                              className="hover:no-underline flex flex-row cursor-pointer"
                              to={menuItem?.to}
                              onClick={menuItem?.pageName === COMPARISON_CONSTANT.LOGOUT && logOut}
                            >
                              <div className="py-2 pl-2">{menuItem?.icon}</div>
                              <BaseText
                                title={menuItem?.pageName}
                                fontWeight="text-semibold"
                                textColor="text-gray-500"
                                className="px-2 py-2"
                              />
                            </Link>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Menu>

                <div className="flex flex-row gap-2 items-center cursor-pointer p-2" onClick={() => setOpen(true)}>
                  <Avatar
                    avatarImage={IS_SSO_ON ? null : companyFormData?.logoPublic}
                    avatarName={firstName ? firstName?.charAt(0) : user?.email?.charAt(0)}
                    avatarStyling="w-8 h-8"
                    name={
                      profileData?.firstName
                        ? getAvatarText(profileData?.firstName, profileData?.lastName)
                        : user?.email?.charAt(0)
                    }
                    fontSize="text-base"
                  />
                </div>
              </div>
            </div>
          </Toolbar>
        )}
      </AppBar>
      <main id="main-container" className={isMobileApp ? 'py-4 px-2 md:mb-0 mb-20' : 'py-14 mt-4 px-2 md:mb-0 mb-20'}>
        {children}
      </main>
      <footer className="md:pb-5 sm:pb-20">
        <div className="flex justify-center">
          <img src={FooterLogo} alt={FooterLogo} />
        </div>
      </footer>
      <Paper
        sx={{
          position: 'fixed',
          bottom: -6,
          left: 0,
          right: 0,
          zIndex: 12,
          borderTopLeftRadius: '32px',
          borderTopRightRadius: '32px',
          paddingTop: '14px',
          paddingBottom: '30px'
        }}
        elevation={3}
      >
        <BottomNavigation
          onChange={handleChange}
          value={value}
          showLabels
          sx={{
            '&.MuiBottomNavigation-root': {
              marginLeft: '20px',
              marginRight: '20px'
            }
          }}
        >
          {navigateMenu?.map((navigate) => (
            <BottomNavigationAction
              sx={{
                '&.MuiBottomNavigationAction-root': {
                  padding: '0px',
                  minWidth: '70px'
                },
                '& .Mui-selected': {
                  backgroundColor: 'transparent',
                  color: 'inherit'
                }
              }}
              label={
                <MSText
                  textColor={`${handleActiveTab(navigate?.to) && 'text-gray-450'}`}
                  fontWeight={`${handleActiveTab(navigate?.to) && 'text-bold'}`}
                  className="mt-2"
                  title={navigate?.pageName}
                />
              }
              value={navigate?.to}
              icon={
                <img
                  src={handleActiveTab(navigate?.to) ? navigate?.activeIcon : navigate?.icon}
                  width={24}
                  height={24}
                  alt={navigate?.pageName}
                />
              }
            />
          ))}
        </BottomNavigation>
      </Paper>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    getProfile: state?.userProfieReducer?.getUserProfileDetails?.data,
    getCompanyInfo: state?.accountSettings?.getCompanyDetails?.data?.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => dispatch(userProfileActions.getUserProfile()),
    getCompanyDetails: () => dispatch(accountSettingActions.getCompanyDetails())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileNavigation);
