import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, navigate } from 'gatsby';
import MSText from '../ui/typography/MSText';
import Avatar from '../ui/Avatar';
import { clearBrowserStorageAndRedirect, isBrowser, openExternalLink, stringTruncate } from '../../helper/helpers';
import {
  COMPANY_INCORPORATION_STATUS,
  ONBOARDING_BASE_REDIRECTION_URL,
  PAYMENT_APP_APPROVED_APPLICATION_URL,
  REDIRECTION_SOURCE,
  REDIRECT_SCREEN_INFO,
  SSO_SITE_AUTHENTICATION_URL,
  STRING_MAX_CHAR,
  SVG_TYPES,
  USER_APP_ACCESS_STATUS,
  USER_APP_IDENTIFIER
} from '../../constants/enum';
import '../../styles/headerNavigation.scss';
import Badge from '../companyList/Badge';
import Tick from '../../images/icons/check-box-tick.svg';
import { connect, useSelector } from 'react-redux';
import * as accountSettingActions from '../accountSetting/reduxStore/action';
import { API_CODE } from '../../constants/enum';
import { getSvgIcon } from '../../helper/svgHelper';
import { EXTERNAL_LINKS } from '../../constants/enum';
import SkeletonRectangle from '../common/ReactangleSkeleton';
function NavigationDropDownLeft({
  invoiceCompanyList,
  incorporationCompanyList,
  businessAccountCompanyList,
  isLeftDropDownOpen,
  applicationAccessList
}) {
  const [onHover, setOnHover] = useState();
  const [openBussinessAccountList, setOpenBussinessAccountList] = useState(false);
  const [openIncorporationList, setOpenIncorporationList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const token = isBrowser() && sessionStorage.getItem('token');
  const userCode = isBrowser() && sessionStorage.getItem('userCode');
  const COMPANY_INCORPORATION = process.env.COMPANY_INCORPORATION_URL;

  const switchCompany = (companyId, status) => {
    const companyRedirectionUrl = `${COMPANY_INCORPORATION}/login?identifierCode=${companyId}&token=${token}&source=${REDIRECTION_SOURCE.INVOICE_APP}&status=${status}`;
    clearBrowserStorageAndRedirect(companyRedirectionUrl);
  };
  const switchInvoiceCompany = (companyId) => {
    isBrowser() && sessionStorage.setItem('companyId', companyId);
    navigate(
      `${process.env.SITE_URL}/login?identifierCode=${companyId}&token=${token}&source=${REDIRECTION_SOURCE.INVOICE_APP}`
    );
  };
  const handleNewCompanyCreation = () => {
    const newCompoanyRegistrationUrl = `${COMPANY_INCORPORATION}/signup/start?token=${token}&source=${REDIRECTION_SOURCE.INVOICE_APP}`;
    clearBrowserStorageAndRedirect(newCompoanyRegistrationUrl);
  };
  const handleSwitchToStatrys = () => {
    const switchToStatrys = process.env.SWITCH_TO_STATRYS;
    openExternalLink(switchToStatrys, '_blank');
  };

  function handleApplyForBusinessAccount() {
    const token = sessionStorage.getItem('token');
    const userCode = sessionStorage.getItem('userCode');
    if (!token || !userCode) {
      console.error('Missing token or userCode');
      return;
    }
    const params = new URLSearchParams({
      prospectCode: '',
      newApplication: true,
      applicationCompletion: 0,
      userLogin: token,
      customerID: userCode
    }).toString();
    const redirectUrl = `${ONBOARDING_BASE_REDIRECTION_URL}?${params}`;
    clearBrowserStorageAndRedirect(redirectUrl);
  }

  function handleBusinessAccount(itemData) {
    if (!itemData?.status) return;
    const token = sessionStorage.getItem('token');
    const userCode = sessionStorage.getItem('userCode');
    const redirectMap = {
      [REDIRECT_SCREEN_INFO.UNDER_REVIEW]: {
        url: SSO_SITE_AUTHENTICATION_URL,
        params: {
          token,
          identifierCode: itemData.identifierCode,
          type: itemData.type,
          status: itemData.status,
          source: REDIRECTION_SOURCE.COMPANY_MANAGEMENT_APP,
          redirectTo: REDIRECT_SCREEN_INFO.UNDER_REVIEW
        }
      },
      [REDIRECT_SCREEN_INFO.UNFINISHED]: {
        url: ONBOARDING_BASE_REDIRECTION_URL,
        params: {
          isFinishedBtnClicked: true,
          applicationCompletion: itemData.completionPercentage,
          prospectCode: itemData.identifierCode,
          customerID: userCode,
          userLogin: token,
          newApplication: ''
        }
      },
      [REDIRECT_SCREEN_INFO.APPROVED]: {
        url: PAYMENT_APP_APPROVED_APPLICATION_URL,
        params: {
          identifierCode: itemData.identifierCode,
          type: itemData.type,
          token,
          status: itemData.status,
          source: REDIRECTION_SOURCE.COMPANY_MANAGEMENT_APP
        }
      }
    };
    const redirectInfo = redirectMap[itemData.status];
    if (redirectInfo) {
      const queryParams = new URLSearchParams(redirectInfo.params).toString();
      clearBrowserStorageAndRedirect(`${redirectInfo.url}?${queryParams}`);
    }
  }

  const applicationAccessListData = useSelector((state) => state?.accountSettings?.applicationAccessListDetails?.data);
  const businessCompanyListData = useSelector((state) => state?.accountSettings?.businessCompanyListDetails);
  const incorporationCompanyListData = useSelector(
    (state) => state?.accountSettings?.incorporationCompanyListDetails?.data?.data
  );
  const invoiceCompanyListData = useSelector((state) => state?.accountSettings?.invoiceCompanyListDetails?.data?.data);
  const apisLoading = useSelector((state) => state?.accountSettings?.loading);
  const hasAccess = useCallback(
    (appCode) =>
      applicationAccessListData?.some(
        (app) => app?.applicationCode === appCode && app?.status === USER_APP_ACCESS_STATUS.APPROVED
      ),
    [applicationAccessListData]
  );

  const companyListHandler = async () => {
    await applicationAccessList(userCode);
    invoiceCompanyListHandler();
  };

  const invoiceCompanyListHandler = async () => {
    setIsLoading(true);
    if (
      applicationAccessListData?.length &&
      hasAccess(USER_APP_IDENTIFIER.INVOICING_APP) &&
      !invoiceCompanyListData?.length
    ) {
      const invoiceCompanyListResponse = await invoiceCompanyList();
      if (invoiceCompanyListResponse?.status === API_CODE.STATUS_200) {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const businessAndIncorporationHandler = async () => {
    if (openBussinessAccountList && hasAccess(USER_APP_IDENTIFIER.PAYMENT_APP) && !businessCompanyListData?.length) {
      await businessAccountCompanyList(userCode);
    }
    if (
      openIncorporationList &&
      hasAccess(USER_APP_IDENTIFIER.COMPANY_MANAGEMENT_APP) &&
      !incorporationCompanyListData?.length
    ) {
      await incorporationCompanyList();
    }
  };

  useEffect(() => {
    businessAndIncorporationHandler();
  }, [openBussinessAccountList, openIncorporationList, hasAccess]);

  useEffect(() => {
    companyListHandler();
  }, [!applicationAccessListData?.length]);
  return (
    <>
      <div
        className={`absolute dropdown-content-container-left sm:left-2 sm:left-[25px]`}
        onClick={(e) => {
          if (isLeftDropDownOpen) {
            e.stopPropagation();
          }
        }}
      >
        <div className="dropdown-content" style={isLeftDropDownOpen ? { display: 'block' } : {}}>
          <div className="tooltip-container">
            <div className="tooltip-icon " style={{ position: 'absolute', left: '16px' }}></div>
          </div>
          <div
            className="dropdown-content-container overflow-auto pb-2 px-2 flex flex-col"
            onMouseLeave={() => setOnHover()}
          >
            {/* Invoice List */}
            <div className={invoiceCompanyListData?.length > 0 && 'mt-2'}>
              {isLoading ? (
                <div className="px-4 flex flex-col gap-3 mt-2">
                  <SkeletonRectangle />
                  <SkeletonRectangle />
                  <SkeletonRectangle />
                </div>
              ) : (
                <>
                  {invoiceCompanyListData?.map((company) => {
                    return (
                      <>
                        <div
                          className="flex flex-row gap-4 items-center cursor-pointer px-2 py-2 hover:bg-coral-150 rounded-lg"
                          onClick={() => switchInvoiceCompany(company?.identifierCode)}
                        >
                          <div className="rounded-full">
                            <Avatar
                              avatarStyling="w-10 h-10"
                              avatarImage={company?.logo}
                              avatarName={company?.companyName && company?.companyName?.charAt(0)}
                            />
                          </div>
                          <div className="flex flex-col w-full gap-1">
                            <div className="flex flex-row items-start justify-between w-full gap-2">
                              <MSText
                                title={stringTruncate(company?.companyName?.toUpperCase(), STRING_MAX_CHAR.TWENTY_NINE)}
                              />
                              {company?.identifierCode === sessionStorage.getItem('companyId') &&
                                invoiceCompanyListData?.length === 1 && <img src={Tick} width="16" height="16" />}
                            </div>
                          </div>
                        </div>
                        <div className="divider" />
                      </>
                    );
                  })}
                </>
              )}
            </div>

            {/* Business Account */}
            <div className="mt-1">
              <div
                className={`flex flex-row items-center rounded-lg px-2 py-2 mt-2 ${
                  !onHover && 'hover:bg-coral-150 cursor-pointer'
                } `}
                onClick={() => {
                  setOpenBussinessAccountList((prev) => !prev);
                  setOpenIncorporationList(false);
                }}
              >
                <div>{getSvgIcon(SVG_TYPES.BUSINESS_ACCOUNT)}</div>
                <div className="flex justify-between items-center flex-grow">
                  <MSText title="Business Account" textColor="text-blue-300" className="dropdown-email-text ml-4" />
                  {openBussinessAccountList && getSvgIcon(SVG_TYPES.ARROW_UP)}
                  {!openBussinessAccountList && getSvgIcon(SVG_TYPES.ARROW_DOWN)}
                </div>
              </div>
              {openBussinessAccountList && (
                <>
                  {apisLoading ? (
                    <div className="px-2 flex flex-col gap-3 mt-2">
                      <SkeletonRectangle />
                      <SkeletonRectangle />
                      <SkeletonRectangle />
                    </div>
                  ) : (
                    <>
                      <div className="divider mt-1" />
                      <div className="flex flex-col">
                        {businessCompanyListData?.map((account) => (
                          <>
                            <ApplicationListItem
                              key={account._id}
                              itemData={account}
                              switchBusinessCompany={handleBusinessAccount}
                            />
                            <div className="divider" />
                          </>
                        ))}
                      </div>
                    </>
                  )}
                  <div
                    className={`flex flex-row items-center rounded-lg p-4  relative ${
                      !onHover && 'hover:bg-coral-150'
                    } `}
                    onClick={businessCompanyListData?.length ? handleApplyForBusinessAccount : undefined}
                  >
                    {businessCompanyListData?.length ? (
                      <div className="flex justify-center items-center ml-1">
                        <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
                        <MSText
                          title="Apply for a Business Account"
                          textColor="text-blue-300"
                          className="dropDown-email-text ml-4 cursor-pointer w-full"
                        />
                      </div>
                    ) : (
                      <>
                        <div className="tooltip-switcher-container ml-1">
                          <div className="flex justify-center items-center">
                            <div>{getSvgIcon(SVG_TYPES.GRAY_ADD_CIRCLE)}</div>
                            <span className="ml-4 text-sm text-gray-450 tooltip-switcher-target">
                              Apply for a Business Account
                            </span>
                          </div>
                          <div className="mt-1 p-2 w-max text-white rounded-lg px-2 py-1 text-sm tooltip-switcher-text">
                            This feature will be available soon!
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>

            {/* Company Management */}

            <div className="mt-1">
              <div
                className={`flex flex-row items-center rounded-lg px-2 py-2 mt-2  ${
                  !onHover && 'hover:bg-coral-150 cursor-pointer'
                } `}
                onClick={() => {
                  setOpenBussinessAccountList(false);
                  setOpenIncorporationList((prev) => !prev);
                }}
              >
                <div>{getSvgIcon(SVG_TYPES.INCORPORATION)}</div>
                <div className="flex justify-between items-center flex-grow">
                  <MSText title="Company Management" textColor="text-blue-300" className="dropdown-email-text ml-4" />
                  {openIncorporationList && getSvgIcon(SVG_TYPES.ARROW_UP)}
                  {!openIncorporationList && getSvgIcon(SVG_TYPES.ARROW_DOWN)}
                </div>
              </div>
              {openIncorporationList && (
                <>
                  {apisLoading ? (
                    <div className="px-2 flex flex-col gap-3 mt-2 mb-3">
                      <SkeletonRectangle />
                      <SkeletonRectangle />
                      <SkeletonRectangle />
                    </div>
                  ) : (
                    <>
                      <div className="divider mt-1" />
                      <div>
                        {incorporationCompanyListData
                          ?.filter((companyDetails) => companyDetails?.status === COMPANY_INCORPORATION_STATUS.APPROVED)
                          ?.map((company) => {
                            return (
                              <>
                                <div
                                  className="flex flex-row gap-4 items-center cursor-pointer px-2 py-2 hover:bg-coral-150 rounded-lg"
                                  onClick={() => switchCompany(company?.identifierCode, company?.status)}
                                >
                                  <div className="rounded-full">
                                    <Avatar
                                      avatarStyling="w-10 h-10"
                                      avatarImage={company?.logo}
                                      avatarName={company?.companyName && company?.companyName?.charAt(0)}
                                    />
                                  </div>
                                  <div className="flex flex-col w-full">
                                    <div className="flex flex-row items-start justify-between w-full gap-1">
                                      <MSText
                                        title={stringTruncate(
                                          company?.companyName?.toUpperCase(),
                                          STRING_MAX_CHAR.TWENTY_NINE
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="divider" />
                              </>
                            );
                          })}
                        {incorporationCompanyListData
                          ?.filter(
                            (companyDetails) => companyDetails?.status === COMPANY_INCORPORATION_STATUS.UNDER_REVIEW
                          )
                          ?.map((company) => {
                            return (
                              <>
                                <div
                                  className="flex flex-row gap-4 items-center cursor-pointer px-2 py-2 hover:bg-coral-150 rounded-lg"
                                  onClick={() => switchCompany(company?.identifierCode, company?.status)}
                                >
                                  <div className="rounded-full">
                                    <Avatar
                                      avatarStyling="w-10 h-10"
                                      avatarImage={company?.logo}
                                      avatarName={company?.companyName && company?.companyName?.charAt(0)}
                                    />
                                  </div>
                                  <div className="flex flex-col w-full">
                                    <div className="flex flex-col items-start justify-between w-full">
                                      <MSText
                                        title={stringTruncate(
                                          company?.companyName?.toUpperCase(),
                                          STRING_MAX_CHAR.TWENTY_NINE
                                        )}
                                      />
                                      <div className="bg-green-200 badge-status">Under Review</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="divider" />
                              </>
                            );
                          })}
                        {incorporationCompanyListData
                          ?.filter(
                            (companyDetails) => companyDetails?.status === COMPANY_INCORPORATION_STATUS.UNFINISHED
                          )
                          ?.map((company) => {
                            return (
                              <>
                                <div
                                  className="flex flex-row gap-4 items-center cursor-pointer px-2 py-2 hover:bg-coral-150 rounded-lg"
                                  onClick={() => switchCompany(company?.identifierCode, company?.status)}
                                >
                                  <div className="rounded-full">
                                    <Avatar
                                      avatarStyling="w-10 h-10"
                                      avatarImage={company?.logo}
                                      avatarName={company?.companyName && company?.companyName?.charAt(0)}
                                    />
                                  </div>
                                  <div className="flex flex-col w-full">
                                    <div className="flex flex-col items-start justify-between w-full">
                                      <MSText
                                        title={stringTruncate(
                                          company?.companyName?.toUpperCase(),
                                          STRING_MAX_CHAR.TWENTY_NINE
                                        )}
                                      />
                                      <div className="bg-salmon-500 badge-status">Unfinished Application</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="divider" />
                              </>
                            );
                          })}
                        {incorporationCompanyListData
                          ?.filter(
                            (companyDetails) => companyDetails?.status === COMPANY_INCORPORATION_STATUS.EXPIRING_SOON
                          )
                          ?.map((company) => {
                            return (
                              <>
                                <div
                                  className="flex flex-row gap-4 items-center cursor-pointer px-2 py-2 hover:bg-coral-150 rounded-lg"
                                  onClick={() => {
                                    switchCompany(company?.identifierCode, company?.status);
                                  }}
                                >
                                  <div className="rounded-full">
                                    <Avatar
                                      avatarStyling="w-10 h-10"
                                      avatarImage={company?.logo}
                                      avatarName={company?.companyName && company?.companyName?.charAt(0)}
                                    />
                                  </div>
                                  <div className="flex flex-col w-full">
                                    <div className="flex flex-col items-start justify-between w-full">
                                      <MSText
                                        title={stringTruncate(
                                          company?.companyName?.toUpperCase(),
                                          STRING_MAX_CHAR.TWENTY_NINE
                                        )}
                                      />
                                      <div className="bg-yellow-500 badge-status">License Expiring Soon</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="divider" />
                              </>
                            );
                          })}
                      </div>
                    </>
                  )}

                  {/* Register another company */}
                  <div className="my-2">
                    <div
                      className={`flex flex-row items-center rounded-lg px-5 py-2 ${!onHover && 'hover:bg-coral-150'} `}
                      onClick={() => handleNewCompanyCreation()}
                    >
                      <div>{getSvgIcon(SVG_TYPES.ADD_CIRCLE)}</div>
                      <MSText
                        title="Register a Company"
                        textColor="text-blue-300"
                        className="dropdown-email-text ml-4 cursor-pointer w-full"
                      />
                    </div>
                    {/* Switch to  statrys */}
                    <div
                      className={`flex flex-row items-center rounded-lg px-5 py-2 mt-2 ${
                        !onHover && 'hover:bg-coral-150'
                      } `}
                      onClick={() => handleSwitchToStatrys()}
                    >
                      <div>{getSvgIcon(SVG_TYPES.SWITCH_TO_STATRYS)}</div>
                      <MSText
                        title="Switch to Statrys"
                        textColor="text-blue-300"
                        className="dropdown-email-text ml-4 cursor-pointer w-full"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    invoiceCompanyList: (companyPayload) => dispatch(accountSettingActions.invoiceCompanyList(companyPayload)),
    incorporationCompanyList: (companyPayload) =>
      dispatch(accountSettingActions.incorporationCompanyList(companyPayload)),
    businessAccountCompanyList: (userCode) => dispatch(accountSettingActions.businessAccountCompanyList(userCode)),
    applicationAccessList: (userCode) => dispatch(accountSettingActions.applicationAccessList(userCode))
  };
};
export default connect(null, mapDispatchToProps)(NavigationDropDownLeft);
NavigationDropDownLeft.propTypes = {
  menuItems: PropTypes.array,
  needSeparator: PropTypes.bool,
  separtorPositions: PropTypes.array,
  children: PropTypes.any,
  invoiceCompanyList: PropTypes.func,
  incorporationCompanyList: PropTypes.func,
  businessAccountCompanyList: PropTypes.func,
  applicationAccessList: PropTypes.func
};

function ApplicationListItem({ itemData, switchBusinessCompany }) {
  return (
    <div
      className="flex flex-row gap-2 items-center cursor-pointer px-2 py-2 hover:bg-coral-100 rounded-lg"
      onClick={() => switchBusinessCompany(itemData)}
    >
      <div className="application-list-item-container flex items-center gap-4">
        <Avatar avatarStyling="w-10 h-10" avatarImage={itemData?.logo} avatarName={itemData?.companyName?.charAt(0)} />
        <div className="flex flex-col w-full gap-1">
          <div className="flex flex-col items-start justify-between w-full">
            <MSText title={stringTruncate(itemData?.companyName.toUpperCase(), STRING_MAX_CHAR.TWENTY_NINE)} />
            {itemData?.statusDisplayValue && (
              <div className="status-pill" style={{ backgroundColor: itemData?.statusBackgroundColor }}>
                {itemData?.statusDisplayValue}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
ApplicationListItem.propTypes = {
  itemData: PropTypes.object
};
