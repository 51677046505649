import React, { useEffect, useState } from 'react';
import { convertToBase64, downloadImage, isBrowser } from '../../helper/helpers';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import { connect } from 'react-redux';
import * as commonAction from '../commonReduxStore/reduxStore/action';
import { EVENT_NAME, REDIRECTION_SOURCE, SESSION_STORAGE_NAME } from '../../constants/enum';

function DownloadDocument({ invoiceId, getPdfSignedUrl, buttonData, documentName }) {
  const [isLoading, setIsLoading] = useState(false);
  const isMobileApp =
    isBrowser() && sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) === REDIRECTION_SOURCE.MOBILE_APP;
  const handleDownload = async () => {
    setIsLoading(true);
    const documentUrl = await getPdfSignedUrl({
      invoiceId: invoiceId,
      isDownloadPDF: true
    });
    if (isMobileApp) {
      const convertedBase64Link = await convertToBase64(documentUrl?.data?.pdfSignedURL);
      const data = {
        event: EVENT_NAME.DOWNLOAD_DOCUMENTS,
        data: {
          url: convertedBase64Link,
          fileName: `${documentUrl?.data?.documentCode}`
        }
      };
      isBrowser() && window.ReactNativeWebView.postMessage(JSON.stringify(data));
      setIsLoading(false);
    } else {
      downloadImage(documentUrl?.data?.pdfSignedURL, documentName);
      setIsLoading(false);
    }
  };
  return (
    <>
      <PrimaryButton
        id={buttonData.id}
        bgColor={buttonData?.bgColor}
        color={buttonData?.textColor}
        className={buttonData?.className}
        caption={buttonData?.caption}
        onClick={() => handleDownload()}
        isLoading={isLoading}
        type="small"
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  getPdfSignedUrl: (getPdfSignedUrlPayload) => dispatch(commonAction.getPdfSignedUrl(getPdfSignedUrlPayload))
});

export default connect(null, mapDispatchToProps)(DownloadDocument);
