import React, { useEffect, useState } from 'react';
import '../../styles/pages/login.scss';
import LoginImgBanner from './LoginImgBanner';
import LoginForm from './LoginForm';
import StatrysLogo from '../../images/icons/statrysLogo.svg';
import BaseText from '../ui/typography/BaseText';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import XSText from '../ui/typography/XSText';
import {
  API_CODE,
  EVENT_NAME,
  IS_SSO_ON,
  LOGIN_TAB,
  MARKETING_LOGIN_URL,
  REDIRECTION_SOURCE,
  SESSION_STORAGE_NAME,
  STATUS
} from '../../constants/enum';
import Seo from '../seo';
import { PATH_PAGE } from '../../routes/paths';
import { connect } from 'react-redux';
import * as LoginActions from './reduxStore/action';
import PropTypes from 'prop-types';
import { isLoggedIn } from '../authService/auth';
import StatrysLoader from '../ui/loaders/StatrysLoader';
import { isBrowser, setCompleteAuthInSessionStorage } from '../../helper/helpers';

function LoginPage({ updateSSOToken }) {
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const source = params.get('source');
  const token = params.get('token');
  const companyId = params.get('identifierCode');

  if (isBrowser() && source === REDIRECTION_SOURCE.MOBILE_APP) {
    isBrowser() && sessionStorage.setItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE, source);
  }

  const tab = params.get('tab');
  const defaultTabSelector = () => {
    if (tab === 'company-management') return LOGIN_TAB?.COMPANY_MANAGEMENT;
    else if (tab === 'invoicing-software') {
      return LOGIN_TAB?.INVOICE;
    } else {
      return LOGIN_TAB?.INVOICE;
    }
  };
  const [activeLoginForm, setActiveLoginForm] = useState(defaultTabSelector());

  const handleActiveTab = (activeTab) => {
    setActiveLoginForm(activeTab);
    if (activeTab === LOGIN_TAB?.INVOICE) {
      navigate('?tab=invoicing-software');
    }
    if (activeTab === LOGIN_TAB?.COMPANY_MANAGEMENT) {
      navigate(`${process.env.COMPANY_INCORPORATION_URL}?tab=company-management`);
    }
  };

  const singleSignOnhandler = async () => {
    if (token && companyId) {
      const payload = {
        companyId: companyId
      };
      const updateSSOTokenResponse = await updateSSOToken(payload, token);
      if (updateSSOTokenResponse?.status === API_CODE.STATUS_200) {
        const completeAuthInfo = updateSSOTokenResponse?.data?.data;
        isBrowser() && sessionStorage.setItem('token', completeAuthInfo?.token);
        isBrowser() && sessionStorage.setItem('userCode', completeAuthInfo?.userCode);
        setCompleteAuthInSessionStorage(completeAuthInfo);
        isBrowser() && sessionStorage.setItem('companyId', companyId);

        if (isLoggedIn()) {
          navigate(PATH_PAGE.dashboard, { replace: true });
        }
        if (
          isBrowser() &&
          sessionStorage.getItem(SESSION_STORAGE_NAME.REDIRECTION_SOURCE) === REDIRECTION_SOURCE.MOBILE_APP
        ) {
          const data = {
            event: EVENT_NAME.TOKEN_UPDATE,
            data: updateSSOTokenResponse?.data?.data
          };
          isBrowser() && window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
      } else {
        navigate(MARKETING_LOGIN_URL, { replace: true });
      }
    } else {
      navigate(MARKETING_LOGIN_URL, { replace: true });
    }
  };

  useEffect(() => {
    if (IS_SSO_ON) {
      singleSignOnhandler();
    }
  }, []);

  return (
    <div className="login-layout">
      <Seo
        title={
          activeLoginForm === LOGIN_TAB?.INVOICE
            ? 'Login - Invoicing | Statrys'
            : 'Login - Company Management | Statrys'
        }
        description={
          activeLoginForm === LOGIN_TAB?.INVOICE
            ? 'Log into your Statrys Invoicing Account.'
            : 'Log into your Company Management Portal.'
        }
      />
      {IS_SSO_ON && companyId && token ? (
        <div className="flex justify-center items-center w-full h-screen">
          <StatrysLoader />
        </div>
      ) : (
        <>
          {!IS_SSO_ON && (
            <LoginImgBanner activeLoginForm={activeLoginForm}>
              <div className="bg-white md:p-10 py-10 px-4 rounded-lg md:mt-0">
                <div className="mb-10">
                  <Link to={process.env.MARKETING_SITE_URL}>
                    <img src={StatrysLogo} alt="Statrys Logo" />
                  </Link>
                </div>
                <BaseText className="mb-4" title="Choose the service you want to use" />
                <div className="flex mb-4 cursor-pointer">
                  <a
                    href={MARKETING_LOGIN_URL}
                    className={`w-full hover:no-underline border flex justify-center items-center gap-1 ${
                      activeLoginForm === LOGIN_TAB?.BUSINESS_ACCOUNT
                        ? 'border-coral-500 bg-salmon-500'
                        : 'border-gray-200'
                    } rounded-l-lg px-3 py-2`}
                  >
                    <XSText
                      textAlign="text-center"
                      textColor={activeLoginForm === LOGIN_TAB?.BUSINESS_ACCOUNT ? 'text-coral-500' : 'text-gray-500'}
                      fontWeight={activeLoginForm === LOGIN_TAB?.BUSINESS_ACCOUNT ? 'text-bold' : 'text-medium'}
                      title={'Business \nAccount'}
                      className="mx-auto whitespace-pre-line"
                    />
                  </a>
                  <div
                    className={`w-full border hover:no-underline ${
                      activeLoginForm === LOGIN_TAB?.INVOICE ? 'border-coral-500 bg-salmon-500' : 'border-gray-200'
                    } px-3 py-2 flex items-center justify-center"`}
                    onClick={() => handleActiveTab(LOGIN_TAB?.INVOICE)}
                  >
                    <XSText
                      textAlign="text-center"
                      textColor={activeLoginForm === LOGIN_TAB?.INVOICE ? 'text-coral-500' : 'text-gray-500'}
                      fontWeight={activeLoginForm === LOGIN_TAB?.INVOICE ? 'text-bold' : 'text-medium'}
                      title={`Invoicing\nSoftware`}
                      className="mx-auto whitespace-pre-line"
                    />
                  </div>
                  <div
                    className={`w-full border hover:no-underline ${
                      activeLoginForm === LOGIN_TAB?.COMPANY_MANAGEMENT
                        ? 'border-coral-500 bg-salmon-500'
                        : 'border-gray-200'
                    } rounded-r-lg px-3 py-2 flex items-center justify-center"`}
                    onClick={() => handleActiveTab(LOGIN_TAB?.COMPANY_MANAGEMENT)}
                  >
                    <XSText
                      textAlign="text-center"
                      textColor={activeLoginForm === LOGIN_TAB?.COMPANY_MANAGEMENT ? 'text-coral-500' : 'text-gray-500'}
                      fontWeight={activeLoginForm === LOGIN_TAB?.COMPANY_MANAGEMENT ? 'text-bold' : 'text-medium'}
                      title={`Company\nManagement`}
                      className="mx-auto whitespace-pre-line"
                    />
                  </div>
                </div>
                <LoginForm activeLoginForm={activeLoginForm} />
              </div>
            </LoginImgBanner>
          )}
        </>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSSOToken: (payload, token) => dispatch(LoginActions.updateSSOToken(payload, token))
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);
LoginPage.propTypes = {
  updateSSOToken: PropTypes.func
};
