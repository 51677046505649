import * as actionTypes from './actionTypes';

let initialState = {
  companyDetails: {},
  getCompanyDetails: {},
  updatedCompanyDetails: {},
  incorporationCompanyListDetails: {},
  invoiceCompanyListDetails: {},
  businessCompanyListDetails: null,
  applicationAccessListDetails: {},
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.UPDATE_COMPANY_DETAILS_REQUEST_SUCCESS:
      return { ...state, companyDetails: { ...action.companyDetails }, loading: false, error: false };

    case actionTypes.CREATE_COMPANY_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CREATE_COMPANY_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.CREATE_COMPANY_DETAILS_REQUEST_SUCCESS:
      return { ...state, updatedCompanyDetails: { ...action.companyDetails }, loading: false, error: false };

    case actionTypes.GET_COMPANY_DETAILS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COMPANY_DETAILS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_COMPANY_DETAILS_REQUEST_SUCCESS:
      return { ...state, getCompanyDetails: { ...action.getCompanyDetails }, loading: false, error: false };

    case actionTypes.INVOICE_COMPANY_LIST_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INVOICE_COMPANY_LIST_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INVOICE_COMPANY_LIST_REQUEST_SUCCESS:
      return { ...state, invoiceCompanyListDetails: { ...action.invoiceCompanyList }, loading: false, error: false };

    case actionTypes.INCORPORATION_COMPANY_LIST_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.INCORPORATION_COMPANY_LIST_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.INCORPORATION_COMPANY_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        incorporationCompanyListDetails: { ...action.incorporationCompanyList },
        loading: false,
        error: false
      };
    case actionTypes.BUSINESS_COMPANY_LIST_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.BUSINESS_COMPANY_LIST_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.BUSINESS_COMPANY_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        businessCompanyListDetails: action.businessAccountCompanyList,
        loading: false,
        error: false
      };
    case actionTypes.APPLICATION_ACCESS_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.APPLICATION_ACCESS_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.APPLICATION_ACCESS_REQUEST_SUCCESS:
      return {
        ...state,
        applicationAccessListDetails: action.applicationAccessList,
        loading: false,
        error: false
      };

    default:
      return state;
  }
};
export default reducer;
