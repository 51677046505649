import React, { useEffect, useState } from 'react';
import DynamicModal from '../ui/modal/DynamicModal';
import MultiTextInput from '../ui/inputs/MultiTextInput';
import TextInput from '../ui/inputs/TextInput';
import { useForm } from 'react-hook-form';
import ValidationMessage from '../ui/ValidationMessage';
import {
  API_CODE,
  CONTACT_TABLE_TITLE,
  FILE_UPLOAD_TYPE,
  REGEX,
  RESPONSIVENESS_BREAKPOINT_VALUE
} from '../../constants/enum';
import PrimaryButton from '../ui/buttons/PrimaryButton';
import { defaultValidationMessage } from '../../helper/helpers';
import UploadImage from '../common/UploadImage';
import MDSubtitleText from '../ui/typography/MDSubtitleText';
import { connect, useSelector } from 'react-redux';
import * as contactActions from './reduxStore/action';
import * as supplierActions from '../../components/supplier/reduxStore/action';
import * as commonReduxActions from '../commonReduxStore/reduxStore/action';
import PropTypes from 'prop-types';
import SelectCountryAutoComplete from '../common/SelectCountryAutoComplete';
import * as userActions from '../invoice/reduxStore/action';
import useWindowDimensions from '../../hooks/useWindowDimensions';

function NewContactModal({
  contactAllList,
  resetContactForm,
  getContactDetails,
  uploadContactImage,
  editContact,
  openDynamicModal,
  closeDynamicModal,
  isEdit,
  createContact,
  getContactById,
  handleOpen,
  prefillCompanyName,
  onSelectingContact,
  getInvoiceDetails,
  invoiceData,
  isTemporary,
  contactType,
  contactData,
  getSupplierById,
  editSupplier
}) {
  const { register, handleSubmit, errors, watch } = useForm();
  const [saveImage, setSaveImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(
    getContactDetails?.profileImagePublic
      ? [{ preview: getContactDetails?.profileImagePublic, type: FILE_UPLOAD_TYPE.URL }]
      : ''
  );
  const [uploadError, setUploadError] = useState(false);
  const [error, setError] = useState(false);
  const [uploadImageUrl, setUploadImageUrl] = useState('');
  const [selectedCountry, setSelectedCountry] = useState();
  const [acceptedFile, setAcceptedFile] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { width } = useWindowDimensions();

  const handleCloseModal = () => {
    setIsLoading(false);
    closeDynamicModal();
    if (!isEdit) {
      setSelectedImage('');
    }
  };

  const onSelectingCountry = (country) => {
    setSelectedCountry(country.name);
  };

  const fileSelection = (acceptedFiles) => {
    setAcceptedFile(acceptedFiles);
    let selectedimg = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file)
      })
    );
    setSelectedImage(selectedimg);
    setSaveImage(true);
  };

  const emailRegex = REGEX.EMAIL_REGEX;

  const getUploadedContactProfileUrl = async (data) => {
    const formData = new FormData();
    formData.append('file', acceptedFile[0]);
    const uploadedContactImage = await uploadContactImage(formData);
    if (uploadedContactImage?.status === API_CODE.STATUS_200) {
      const profileImagePublic = uploadedContactImage?.data?.data?.Location;
      setUploadImageUrl(profileImagePublic);
      if (isTemporary) {
        contactTempInvoiceHandler(data, profileImagePublic);
      } else {
        await createAndEditContactModal(data, profileImagePublic);
      }
    } else {
      setUploadError(true);
    }
  };

  const createAndEditContactModal = async (data, profileImagePublic) => {
    if (!isEdit) {
      const createContactPayload = {
        ...data,
        country: selectedCountry,
        profileImagePublic: profileImagePublic
      };

      const createContactResponse = await createContact(createContactPayload);
      if (createContactResponse?.status === API_CODE.STATUS_200) {
        contactAllList();
        onSelectingContact && onSelectingContact({ _id: createContactResponse?.data?.data?._id });
        handleCloseModal();
      } else {
        setError(true);
      }
    } else {
      const editedContactPayload = {
        ...data,
        country: selectedCountry ? selectedCountry : getContactDetails?.country,
        profileImagePublic: profileImagePublic ? profileImagePublic : getContactDetails?.profileImagePublic,
        contactId: getContactDetails._id
      };
      const editContactResponse = await editContact(editedContactPayload);
      if (editContactResponse?.status === API_CODE.STATUS_200) {
        handleCloseModal();
        getContactById(editContactResponse?.data?.data._id);
        handleOpen(editContactResponse?.data);
      } else {
        setError(true);
      }
    }
  };

  const editSupplierData = async (data) => {
    const editedSupplierPayload = {
      ...data,
      country: selectedCountry ? selectedCountry : contactData?.country,
      supplierId: contactData?._id
    };
    const editSupplierResponse = await editSupplier(editedSupplierPayload);
    if (editSupplierResponse?.status === API_CODE.STATUS_200) {
      handleCloseModal();
      getSupplierById(editSupplierResponse?.data?.data._id);
      handleOpen(editSupplierResponse?.data);
    } else {
      setError(true);
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    if (!isTemporary) {
      if (saveImage) {
        getUploadedContactProfileUrl(data);
      } else {
        if (contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS) {
          createAndEditContactModal(data);
        }
        if (contactType === CONTACT_TABLE_TITLE.ONLY_SUPPLIERS) {
          editSupplierData(data);
        }
      }
    } else {
      if (saveImage) {
        getUploadedContactProfileUrl(data);
      } else {
        contactTempInvoiceHandler(data, getInvoiceDetails?.profileImagePublic);
      }
    }
  };

  const contactTempInvoiceHandler = (data, profileImagePublic) => {
    invoiceData({
      toFirstName: data?.firstName,
      toLastName: data?.lastName,
      toEmail: data?.email,
      toCompanyName: data?.companyName,
      toRegistrationNumber: data?.registrationNumber,
      toWebsite: data?.website,
      toAddress: data?.address,
      toTownCity: data?.city,
      toState: data?.state,
      toPostalCode: data?.postalCode,
      toCountry: selectedCountry ? selectedCountry : getInvoiceDetails?.toCountry,
      toProfile: profileImagePublic,
      profileImagePublic: profileImagePublic,
      ...data,
      country: selectedCountry ? selectedCountry : getInvoiceDetails?.country
    });
    setSaveImage(false);
    handleCloseModal();
  };

  const watchAllFields = watch();

  useEffect(() => {
    setSelectedImage(
      getContactDetails?.profileImagePublic
        ? [{ preview: getContactDetails?.profileImagePublic, type: FILE_UPLOAD_TYPE.URL }]
        : ''
    );
  }, [getContactDetails?.profileImagePublic]);

  useEffect(() => {
    return () => {
      resetContactForm();
    };
  }, []);
  return (
    <>
      <DynamicModal
        openDynamicModal={openDynamicModal}
        closeDynamicModal={() => handleCloseModal()}
        maxWidth="960px"
        minWidth={width < RESPONSIVENESS_BREAKPOINT_VALUE.MEDIUM ? 'sm' : ''}
      >
        <MDSubtitleText
          title={isEdit ? 'Edit contact details' : 'Add new contact'}
          className="mb-8"
          fontWeight="text-bold"
        />
        <form id="create-contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex md:flex-row flex-col">
            <div className={contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS && 'md:mr-6'}>
              <div className="flex md:flex-row flex-col mb-4 gap-4 w-full">
                <TextInput
                  defaultValue={!isTemporary ? contactData?.firstName : getInvoiceDetails?.toFirstName}
                  textFieldContainerClass="w-full"
                  name="firstName"
                  label="First name"
                  variant="filled"
                  inputRef={register({
                    required: false
                  })}
                  error={errors.firstName ? true : false}
                  helperText={
                    errors?.firstName && (
                      <ValidationMessage
                        title={defaultValidationMessage('First name or else provide a valid Company name')}
                      />
                    )
                  }
                />
                <TextInput
                  defaultValue={!isTemporary ? contactData?.lastName : getInvoiceDetails?.toLastName}
                  textFieldContainerClass="w-full"
                  name="lastName"
                  label="Last name"
                  variant="filled"
                  inputRef={register({
                    required: false
                  })}
                />
              </div>
              <TextInput
                defaultValue={
                  !isTemporary
                    ? contactData?.companyName
                      ? contactData?.companyName
                      : prefillCompanyName
                    : getInvoiceDetails?.companyName
                    ? getInvoiceDetails?.companyName
                    : prefillCompanyName
                }
                margin="w-full"
                name="companyName"
                label="Company Name"
                variant="filled"
                inputRef={register({
                  required: watchAllFields.companyName ? false : true
                })}
                error={errors.companyName ? true : false}
                helperText={
                  errors?.companyName &&
                  !watchAllFields.companyName && <ValidationMessage title={defaultValidationMessage('Company Name')} />
                }
              />
              <TextInput
                defaultValue={!isTemporary ? contactData?.registrationNumber : getInvoiceDetails?.registrationNumber}
                margin="mt-4"
                name="registrationNumber"
                label="Registration number"
                variant="filled"
                inputRef={register({
                  required: false
                })}
              />

              <div className="flex md:flex-row flex-col md:gap-4 w-full">
                <TextInput
                  margin="mt-4 w-full"
                  defaultValue={!isTemporary ? contactData?.email : getInvoiceDetails?.email}
                  name="email"
                  label="Email Address"
                  variant="filled"
                  inputRef={register({
                    required: true,
                    pattern: {
                      value: emailRegex,
                      message: 'Please fill in a valid Email Address'
                    }
                  })}
                  error={!!errors.email}
                  helperText={
                    errors?.email &&
                    !watchAllFields.email && <ValidationMessage title={defaultValidationMessage('Email')} />
                  }
                />

                <TextInput
                  defaultValue={!isTemporary ? contactData?.website : getInvoiceDetails?.website}
                  margin="mt-4 w-full"
                  name="website"
                  label="Website"
                  variant="filled"
                  inputRef={register({
                    required: false
                  })}
                />
              </div>

              <div className="flex md:flex-row flex-col md:gap-4 w-full">
                <TextInput
                  defaultValue={!isTemporary ? contactData?.address : getInvoiceDetails?.address}
                  margin="mt-4 w-full"
                  name="address"
                  label="Address"
                  variant="filled"
                  inputRef={register({
                    required: false
                  })}
                />
                <TextInput
                  defaultValue={!isTemporary ? contactData?.city : getInvoiceDetails?.city}
                  margin="mt-4 w-full"
                  name="city"
                  label="City"
                  variant="filled"
                  inputRef={register({
                    required: false
                  })}
                />
              </div>
              <div className="flex md:flex-row flex-col md:gap-4 w-full">
                <TextInput
                  defaultValue={!isTemporary ? contactData?.state : getInvoiceDetails?.state}
                  margin="mt-4 w-full"
                  name="state"
                  label="State"
                  variant="filled"
                  inputRef={register({
                    required: false
                  })}
                />
                <TextInput
                  defaultValue={!isTemporary ? contactData?.postalCode : getInvoiceDetails?.postalCode}
                  margin="mt-4 w-full"
                  name="postalCode"
                  label="Zip / Postal Code"
                  variant="filled"
                  inputRef={register({
                    required: false
                  })}
                />
                <div className="mt-4 w-full">
                  <SelectCountryAutoComplete
                    defaultValue={!isTemporary ? contactData?.country : getInvoiceDetails?.country}
                    setSelectedValue={onSelectingCountry}
                    textFieldContainerClass="w-full"
                    label="Country"
                    name="country"
                    variant="filled"
                  />
                </div>
              </div>
            </div>
            {contactType === CONTACT_TABLE_TITLE.ONLY_CLIENTS && (
              <div className="flex flex-col justify-between md:w-64 md:mt-0 mt-8">
                {isEdit ? (
                  <UploadImage
                    saveImage={saveImage}
                    selectedImage={
                      !isTemporary
                        ? selectedImage
                        : getInvoiceDetails?.profileImagePublic
                        ? [{ preview: getInvoiceDetails?.profileImagePublic, type: FILE_UPLOAD_TYPE.URL }]
                        : ''
                    }
                    uploadError={uploadError}
                    fileSelection={fileSelection}
                  />
                ) : (
                  <UploadImage
                    saveImage={saveImage}
                    selectedImage={selectedImage}
                    uploadError={uploadError}
                    fileSelection={fileSelection}
                    isEdit={isEdit}
                  />
                )}
                <MultiTextInput
                  defaultValue={!isTemporary ? contactData?.notes : getInvoiceDetails?.notes}
                  label="Notes"
                  name="notes"
                  rows="8"
                  inputRef={register({
                    required: false
                  })}
                ></MultiTextInput>
              </div>
            )}
          </div>

          <div className="md:flex gap-4 mt-8 mb-4 w-full">
            <PrimaryButton
              id="new-contact-save-btn"
              isLoading={isLoading}
              className="contact-primary-button md:w-auto w-full md:mb-0 mb-2"
              caption="Save"
              onClick={handleSubmit(onSubmit)}
            />
            <PrimaryButton
              id="new-contact-cancel-btn"
              color="text-blue-300"
              bgColor="bg-white"
              className="contact-white-primary-button md:w-auto w-full"
              caption="Cancel"
              onClick={() => handleCloseModal()}
            />
          </div>
        </form>
      </DynamicModal>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    getContactDetails: state?.contactReducer?.viewContactDetails?.data,
    getInvoiceDetails: state?.invoiceDetails?.invoiceData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    invoiceData: (invoiceData) => dispatch(userActions.invoiceData(invoiceData)),
    resetContactForm: () => dispatch(contactActions.resetContactForm()),
    createContact: (contactData) => dispatch(contactActions.createContact(contactData)),
    uploadContactImage: (uploadedImage) => dispatch(commonReduxActions.publicDocumentUpload(uploadedImage)),
    editContact: (editedDetails) => dispatch(contactActions.editContact(editedDetails)),
    editSupplier: (editedDetails) => dispatch(supplierActions.editSupplier(editedDetails))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewContactModal);

NewContactModal.propTypes = {
  contactAllList: PropTypes.func,
  resetContactForm: PropTypes.func,
  getContactDetails: PropTypes.object,
  uploadContactImage: PropTypes.func,
  editContact: PropTypes.func,
  openDynamicModal: PropTypes.bool,
  closeDynamicModal: PropTypes.func,
  isEdit: PropTypes.bool,
  createContact: PropTypes.func,
  getContactById: PropTypes.func,
  handleOpen: PropTypes.func,
  contactId: PropTypes.string,
  isTemporary: PropTypes.bool
};
